import { Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import taskify from "../../api/taskify";
import CreateTeam from "../../components/createTeam/CreateTeam";
import ModalComponent from "../../components/modal/modal";
import Team from "../../components/team/Team";

const Teams = ({ user }) => {
  const [teams, setTeams] = useState([]);
  const [teamModal, setTeamModal] = useState(false);

  useEffect(() => {
    taskify
      .get(`teams/getTeamsByCompany/${user.companyId}`)
      .then((res) => setTeams(res.data))
      .catch((err) => console.log(err));
  }, [user]);

  const onCreateNewTeam = (team) => {
    setTeams([...teams, team]);
    setTeamModal(false);
  };

  const displayTeams = () => {
    return (
      <div>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <h2>My Teams</h2>
        </Grid>
        {teams.map((team, index) => {
          return <Team key={team.id} team={team} index={index} />;
        })}
      </div>
    );
  };

  return (
    <div>
      {teams.length > 0 ? displayTeams() : null}
      <Link onClick={() => setTeamModal(true)}>Create Team</Link>
      <ModalComponent open={teamModal}>
        <CreateTeam
          onCreateNewTeam={onCreateNewTeam}
          onClose={() => {
            setTeamModal(false);
          }}
        />
      </ModalComponent>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(Teams);
