import React, { useState, useEffect } from "react";
import { makeStyles, Paper, Grid, Typography } from "@material-ui/core";
import moment from "moment";
import { connect } from "react-redux";
import _ from "lodash";
import TaskGrid from "./grid";
import taskify from "../../api/taskify";
import { getTasksByUserId } from "../../redux/actionCreators/taskActionCreators";
const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    margin: 25,
    overflow: "scroll",
  },
  container: {
    width: "100%",
    borderRadius: theme.spacing(2),
    border: "1px solid lightgrey",
    padding: theme.spacing(0, 0, 0, 0),
    margin: theme.spacing(0, 0, 10, 0),
  },
  heading: {
    padding: theme.spacing(2, 3, 2, 3),
    borderTopRightRadius: theme.spacing(2),
    borderTopLeftRadius: theme.spacing(2),
    marginBottom: theme.spacing(3),
    color: "white",
  },
}));

const TaskGridComponent = ({ getTasks, user, tasks }) => {
  const classes = useStyles();

  useEffect(() => {
    taskify
      .get(`/tasks/getTasksByUserId/${user.id}`)
      .then((res) => getTasks(res.data))
      .catch((err) => console.log(err));
  }, [getTasks, user]);

  const displayTasks = () => {
    const next7Days = moment().add(16, "d").format("MM/DD/YYYY h:mm:ss a");
    const currentDate = moment().format("MM/DD/YYYY h:mm:ss a");
    const last30Days = moment()
      .subtract(30, "d")
      .format("MM/DD/YYYY h:mm:ss a");

    const Header = [
      "Most Recent",
      "Current Month",
      "Completed",
      "Upcoming 7 Days",
    ];
    const Done = tasks
      .filter((status) => status.taskStatusId === 3)
      .map((filteredStatus) => filteredStatus);
    const Assigned = tasks
      .filter((status) => status.taskStatusId === 1)
      .map((filteredStatus) => filteredStatus);
    const CurrentMonth = tasks
      .filter((status) => moment(status.createdAt).isBefore(currentDate))
      .filter((status) => moment(status.dueDate).isAfter(last30Days))
      .map((filteredStatus) => filteredStatus);

    const Upcomong7Days = tasks
      .filter((status) => moment(status.dueDate).isBefore(next7Days))
      .filter((status) => moment(status.dueDate).isAfter(currentDate))
      .map((filteredStatus) => filteredStatus);
    console.log(JSON.stringify(Upcomong7Days) + "YYYY");
    const data = [Assigned, CurrentMonth, Done, Upcomong7Days];

    return _.times(3, (item) => {
      console.log(item + "YES");
      return <TaskGrid header={Header[item]} tasks={data[item]} />;
    });
  };

  return (
    <div className={classes.container}>
      <Typography
        style={{ background: "linear-gradient(60deg,#292E49,#BBD2C5)" }}
        className={classes.heading}
        variant="h6"
      >
        Task Activities
      </Typography>
      <Grid container>{displayTasks()}</Grid>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    tasks: state.task.tasks,
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTasks: (tasks) => {
      dispatch(getTasksByUserId(tasks));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TaskGridComponent);
