const { LOGIN, ERROR_LOGIN } = require("../actionTypes/authActionTypes");

const initialState = {
  token: "",
  user: null,
  error: "",
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        token: action.payload.token,
        user: action.payload.user,
      };
    case ERROR_LOGIN:
      return {
        ...state,
        error: action.payload.error,
      };
    default:
      return state;
  }
};
