import { Grid, Paper, makeStyles, Typography } from "@material-ui/core";
import Switch from "react-switch";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import AddWorkspace from "../../components/addWorkspace/addWorkspace";
import Button from "../../components/button/Button";
import ModalComponent from "../../components/modal/modal";
import { asyncAddTemplate } from "../../redux/actionCreators/templateActionCreators";
import { asyncToggleIsActive } from "../../redux/actionCreators/workspaceActionCreators";
import { GrDocumentText } from "react-icons/gr";
import ReactTooltip from "react-tooltip";

const WorkspaceListing = ({ workspaces, asyncAddTemplate, toggleIsActive }) => {
  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
    container: {
      borderRadius: theme.spacing(2),
      border: "1px solid lightgrey",
      padding: theme.spacing(0, 0, 0, 0),
      margin: theme.spacing(0, 0, 10, 0),
    },
    heading: {
      padding: theme.spacing(2, 3, 2, 3),
      borderTopRightRadius: theme.spacing(2),
      borderTopLeftRadius: theme.spacing(2),
      marginBottom: theme.spacing(3),
      color: "white",
    },
  }));

  const classes = useStyles();
  const [data, setData] = useState({
    workspaces: [],
    toggleWorkspaceModal: false,
  });

  useEffect(() => {
    setData((prevState) => {
      return { ...prevState, workspaces: workspaces };
    });
  }, [workspaces]);

  const addWorkSpace = (workspace) => {
    setData((prevState) => {
      return {
        ...prevState,
        workspaces: [...prevState.workspaces, workspace],
        toggleWorkspaceModal: false,
      };
    });
  };

  const createTemplate = (workspaceId) => {
    asyncAddTemplate(workspaceId);
    alert("Template Saved");
  };

  const displayWorkSpaces = () => {
    const { workspaces } = { ...data };
    return workspaces.length > 0
      ? workspaces.map((item, index) => {
          return (
            <React.Fragment>
              <Paper className={classes.paper}>
                <Grid container>
                  <Grid container xs={10}>
                    <Link
                      key={item.id + index}
                      to={`workspace/${item.id}`}
                      color="secondary"
                      variant="contained"
                    >
                      <p style={{ margin: 0 }}>{item.name} </p>
                    </Link>
                  </Grid>
                  {/* <Grid container xs={1}></Grid> */}
                  <Grid container direction="row" xs={2} justify="flex-end">
                    <Switch
                      checked={item.isActive}
                      onChange={() => toggleIsActive(item)}
                      name="isActive"
                      color="primary"
                    />
                    <GrDocumentText
                      data-tip="Save as Template"
                      size={25}
                      color="red"
                      onClick={() => createTemplate(item.id)}
                    />
                    <ReactTooltip />
                  </Grid>
                </Grid>
              </Paper>
            </React.Fragment>
          );
        })
      : null;
  };

  const onModalOpen = () => {
    setData((prevState) => {
      return { ...prevState, toggleWorkspaceModal: true };
    });
  };

  return (
    <div>
      <Button
        style={{ marginBottom: 12 }}
        variant="contained"
        color="primary"
        onClick={onModalOpen}
      >
        Add Workspace
      </Button>

      {displayWorkSpaces()}

      <ModalComponent open={data.toggleWorkspaceModal}>
        <AddWorkspace
          onSave={addWorkSpace}
          onCancel={() => {
            setData((prevState) => {
              return { ...prevState, toggleWorkspaceModal: false };
            });
          }}
        />
      </ModalComponent>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    workspaces: state.workspace.workspaces,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    asyncAddTemplate: (workspaceId) => {
      dispatch(asyncAddTemplate(workspaceId));
    },
    toggleIsActive: (item) => {
      dispatch(asyncToggleIsActive(item));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceListing);
