import React, { memo, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import taskify from "../../api/taskify";
import WorkspaceDetails from "../workspaceDetails/WorkspaceDetails";

const WorkspaceDetialsList = ({ user }) => {
  const [details, setDetails] = useState([]);

  useEffect(() => {
    taskify
      .get(`/workspaces/activeWorkspaceByUserId/${user.id}`)
      .then((res) => {
        setDetails(res.data);
      })
      .catch((err) => console.log(err));
  }, [user]);

  return (
    <div>
      <h2>Active Workspaces</h2>
      {details.map((workspace, index) => {
        return index % 5 < 0 ? (
          <br />
        ) : (
          <Link to={`/workspace/${workspace.id}`}>
            <WorkspaceDetails workspace={workspace} />
          </Link>
        );
      })}
      {details ? null : <p>You don't have any Active Workspaces</p>}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(memo(WorkspaceDetialsList));
