import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import "./App.css";
import Login from "./screens/Login/Login";
import Register from "./screens/Register/Register";
import TopLayer from "./screens/TopLayer/TopLayer";
import AddWorkspace from "./components/addWorkspace/addWorkspace";
import Dashboard from "./screens/dashboard/Dashboard";
import Workspace from "./screens/workspace/Workspace";
import Client from "./screens/Client/Client";
import InvitationLanding from "./screens/InvitationLanding/InvitationLanding";
import TeamDetails from "./components/teamDetails/TeamDetailsComponent";
import workspaceListing from "./screens/workspaceListing/workspaceListing";
import templateListing from "./screens/templateListing/templateListing";
import { connect } from "react-redux";
import AuthGuard from "./services/AuthGuard/AuthGuard";
import userListing from "./components/userListing/userListing";
import Teams from "./screens/Teams/Teams";

const App = ({ token }) => {
  return (
    <div>
      {token ? <TopLayer /> : null}
      <Route path="/login" component={Login} />
      <Route path="/register" component={Register} />

      <main role="main" className="container">
        <Switch>
          <Route path="/invitation/:token" component={InvitationLanding} />

          <AuthGuard
            path="/dashboard"
            component={Dashboard}
            canActivate={["PCA", "Admin", "User"]}
          />

          <AuthGuard
            path="/client"
            component={Client}
            canActivate={["PCA", "Admin"]}
          />

          <AuthGuard
            path="/workspace/:id"
            component={Workspace}
            canActivate={["PCA", "Admin"]}
          />

          <AuthGuard
            path="/workspace"
            component={AddWorkspace}
            canActivate={["PCA", "Admin"]}
          />

          <AuthGuard
            path="/team/:name/:id"
            component={TeamDetails}
            canActivate={["PCA", "Admin"]}
          />

          <AuthGuard
            path="/workspaceListing"
            component={workspaceListing}
            canActivate={["PCA", "Admin"]}
          />

          <AuthGuard
            path="/users"
            component={userListing}
            canActivate={["PCA", "Admin"]}
          />
          <AuthGuard
            path="/teams"
            component={Teams}
            canActivate={["PCA", "Admin"]}
          />

          <AuthGuard
            path="/templateListing"
            component={templateListing}
            canActivate={["PCA", "Admin"]}
          />
        </Switch>
      </main>
      <Redirect from="/" to="/login" />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

export default connect(mapStateToProps)(App);
