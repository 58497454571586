import React, { useState } from "react";
import Input from "../input/Input";
import Button from "../button/Button";
import taskify from "../../api/taskify";
import { Grid } from "@material-ui/core";
import Dropdown from "../dropdown/Dropdown";
import { connect } from "react-redux";

const Group = ({ onSave, workspaceId, closeGroupModal, teamMembers }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [userId, setUserId] = useState(0);

  const onSaveClick = (e) => {
    e.preventDefault();
    const group = { name, description, userId, workspaceId };

    taskify
      .post("/groups", group)
      .then((res) => onSave(res.data))
      .catch((err) => {
        alert("Something Went Wrong");
        closeGroupModal();
      });
  };

  return (
    <div>
      <h2>Create Group</h2>
      <Input
        fullWidth={true}
        variant="outlined"
        id="g-name"
        name="g-name"
        label="Group Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <Input
        fullWidth={true}
        variant="outlined"
        id="g-description"
        name="g-description"
        label="Group Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <Dropdown
        fullWidth={true}
        name="g-owner"
        label="Group Owner"
        options={teamMembers}
        onChange={(e) => setUserId(e.target.value)}
      />
      <Grid container justify="flex-end">
        <Button onClick={closeGroupModal} color="primary" variant="contained">
          Cancel
        </Button>
        <Button onClick={onSaveClick} color="primary" variant="contained">
          Save
        </Button>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    // teamMembers: state.teamMember.teamMembers,
  };
};

export default connect(mapStateToProps)(Group);
