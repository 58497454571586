import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import taskify from "../../api/taskify";
import { srvassignTask, srvAssignTask } from "../../services/TaskServices";
import Button from "../button/Button";
import Dropdown from "../dropdown/Dropdown";
import DateTime from "../../components/dateTimePicker/dateTime";
import moment from "moment";

const AssignTask = ({ taskId, toggleModal, members, onAssignTask }) => {
  const [userId, setUserId] = useState();
  const [date, setDate] = useState(new Date());
  // const [members, setMembers] = useState([]);

  const assignTasks = async () => {
    try {
      const res = await srvAssignTask();
      // setMembers(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    assignTasks();
    // const decoded = JSON.parse(localStorage.getItem("decoded"));

    // taskify
    //   .get(`teamMembers/company/${decoded.companyId}`)
    //   .then((res) => {
    //     console.log(res.data, "Team Members");
    //     setMembers(res.data);
    //   })
  }, []);

  const assignTask = async () => {
    try {
      const res = await srvassignTask(taskId, date, userId);
      const member = members.find((m) => m.id === userId);
      onAssignTask(
        taskId,
        moment(date).format("MM/DD/YYYY h:mm:ss a"),
        member.name
      );
      toggleModal();
      // setMembers(res.data);
    } catch (err) {
      console.log(err);
    }

    // taskify
    //   .put(`/tasks/${taskId}`, { userId })
    //   .then(() => {
    //     toggleModal();
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  return (
    <div>
      <h3>Assign Task</h3>
      <Dropdown
        fullWidth={true}
        name="t-assign"
        label="Assign To"
        options={members}
        onChange={(e) => {
          setUserId(e.target.value);
        }}
      />
      <DateTime
        value={date}
        onChange={(e) => setDate(e._d)}
        isValidDate={(current) => current.isAfter(moment().subtract(1, "day"))}
      />
      <Grid container justify="flex-end">
        <Button onClick={assignTask} color="primary" variant="contained">
          Save
        </Button>
      </Grid>
    </div>
  );
};

export default AssignTask;
