import React, { useState, useEffect } from "react";
import { srvTeamsDetails } from "../../services/TeamServices";

const TeamDetails = ({ match, name }) => {
  const [team, setTeam] = useState([]);
  const [teamId, setTeamId] = useState(match.params.id);

  const teamDetails = async (teamId) => {
    try {
      const res = await srvTeamsDetails(teamId);
      setTeam(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    teamDetails(teamId);
  }, [teamId]);

  const displayTeamMembers = () => {
    return team ? (
      <table className="table">
        <thead>
          <tr>
            <th scope="col">SrNo.</th>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Company</th>
            <th scope="col">is Active</th>
          </tr>
        </thead>
        <tbody>
          {team.map((member, index) => {
            return (
              <tr key={member.id}>
                <td>{index + 1}</td>
                <td>{`${member.user.fName} ${member.user.lName}`}</td>
                <td>{member.user.email}</td>
                <td>{member.user.company.name}</td>
                <td>{member.user.isActive === 1 ? "Yes" : "No"}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    ) : null;
  };
  return (
    <div>
      <h2>{match.params.name} </h2>
      {team.length > 0 ? displayTeamMembers() : null}
    </div>
  );
};

export default TeamDetails;
