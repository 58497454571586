import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { MdCancel } from "react-icons/md";
import { Grid } from "@material-ui/core";
import ButtonComponent from "../button/Button";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate( -${top}%,-${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    outline: "none",
    width: "50vw",
    // height: "85vh",
    overflowY: "scroll",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  close: {
    cursor: "pointer",
    fontSize: "22px",
    color: "#F32013",
  },
}));

const ModalComponent = ({ children, handleClose, open, handleSave }) => {
  // const [open,setOpen]=useState(false)
  const classes = useStyles();

  // const handleOpen = () => {
  //     setOpen(true);
  // };

  // const handleClose = () => {
  //     setOpen(false);
  // };
  const [modalStyle] = React.useState(getModalStyle);

  return (
    <Modal
      style={{ overflowY: "scroll" }}
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        {children}
        {/* <Grid container justify="flex-end">
                <ButtonComponent onClick={handleSave} color="primary"  variant="contained" >Save</ButtonComponent>
                <ButtonComponent onClick={handleClose} color="primary"   variant="contained"  >Cancel</ButtonComponent>
            </Grid> */}
      </div>
    </Modal>
  );
};

export default ModalComponent;
