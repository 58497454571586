import { srvAddTemplate, srvImportTemplate, srvTemplateByUserId } from "../../services/TemplateServices";
import {
  ADD_TEMPLATE,
  ADD_TEMPLATE_ERROR,
  GET_TEMPLATES_BY_USER_ID,
  GET_TEMPLATES_BY_USER_ID_FAILED,
  IMPORT_TEMPLATE,
} from "../actionTypes/templateActionTypes";
import { asyncGetWorkSpaceByUserId } from "./workspaceActionCreators";

const addTemplate = (template) => {
  return {
    type: ADD_TEMPLATE,
    payload: { template },
  };
};

export const asyncAddTemplate = (template) => {
  return async (dispatch) => {
    
    try{
      const res = await srvAddTemplate(template)
      dispatch(addTemplate(res.data));
    }
    catch(err){console.log(err)}
    
    // taskify
    //   .post(`/templates/${workspaceId}`)
    //   .then((template) => {
    //     dispatch(addTemplate(template));
    //   })
    //   .catch((err) => dispatch(addTemplateFailed(err)));
  };
};

export const addTemplateFailed = (error) => {
  return {
    type: ADD_TEMPLATE_ERROR,
    payload: { error },
  };
};

export const getTemplatesByUserId = (templates) => {
  return {
    type: GET_TEMPLATES_BY_USER_ID,
    payload: { templates },
  };
};

export const asyncGetTemplatesByUserId = () => {
  return async (dispatch) => {
    try{
        const TemplateByUserId = await srvTemplateByUserId()
      dispatch(getTemplatesByUserId(TemplateByUserId.data));
    }
    catch(err){console.log(err)}
    // taskify
    //   .get("/templates/templateWorkspace")
    //   .then((res) => {
    //     console.log(res.data, "Templates");
    //     dispatch(getTemplatesByUserId(res.data));
    //   })
    //   .catch((err) => dispatch(getTemplatesByUserIdFailed(err)));
  };
};

export const getTemplatesByUserIdFailed = (error) => {
  return {
    type: GET_TEMPLATES_BY_USER_ID_FAILED,
    payload: { error },
  };
};

export const importTemplate = (template) => {
  return {
    type: IMPORT_TEMPLATE,
    payload: template,
  };
};

export const asyncImportTemplate = (id, userId) => {
  return async (dispatch) => {
   try{
        const res = await srvImportTemplate(id,userId)
             dispatch(getTemplatesByUserId(res.data));
         dispatch(asyncGetWorkSpaceByUserId(userId));
    }
    catch(err){console.log(err)}
    // taskify
    //   .get(`/templates/${id}/${userId}`)
    //   .then((res) => {
    //     dispatch(getTemplatesByUserId(res.data));
    //     dispatch(asyncGetWorkSpaceByUserId(userId));
    //   })
    //   .catch((err) => dispatch(getTemplatesByUserIdFailed(err)));
  };
};
