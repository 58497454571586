import {
  ADD_CLIENT,
  GET_CLIENTS_BY_USER_ID,
} from "../actionTypes/clientActionTypes";

const initialState = {
  clients: [],
};

export const clientReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CLIENT:
      return {
        ...state,
        clients: [...state.clients, action.payload.client],
      };
    case GET_CLIENTS_BY_USER_ID:
      return {
        ...state,
        clients: action.payload.clients,
      };
    default:
      return state;
  }
};
