import {
  ADD_COMMNENT,
  ADD_TASK,
  GET_TASKS_BY_USER_ID,
} from "../actionTypes/taskActionTypes";

const initialState = {
  tasks: [],
};

export const taskReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TASKS_BY_USER_ID:
      return {
        ...state,
        tasks: action.payload.tasks,
      };
    case ADD_TASK:
      return {
        ...state,
        tasks: [...state.tasks, action.payload.task],
      };
    case ADD_COMMNENT:
      const index = state.tasks.findIndex(
        (task) => task.id === action.payload.comment.taskId
      );
      const newTasks = [...state.tasks];
      newTasks[index].comments.push(action.payload.comment);
      console.log(newTasks, "New");

      return {
        ...state,
        tasks: newTasks,
      };
    default:
      return state;
  }
};
