import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    width: "20%",
    marginBottom: "6px",
    float: "left",
    marginLeft: "12px",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});
const WorkspaceDetails = ({ workspace }) => {
  const classes = useStyles();

  const headCounts = ["Assigned", "In Progress", "Done"];
  const [counts, setCounts] = useState({
    assigned: 0,
    inProgress: 0,
    done: 0,
  });

  useEffect(() => {
    let assigned = 0;
    let inProgress = 0;
    let done = 0;
    workspace.groups.map((group) => {
      group.tasks.map((task) => {
        if (task.taskStatusId === 1) assigned += 1;
        else if (task.taskStatusId === 2) inProgress += 1;
        else if (task.taskStatusId === 3) done += 1;
      });
    });
    setCounts((prevState) => {
      return {
        ...prevState,
        assigned,
        inProgress,
        done,
      };
    });
  }, [workspace]);

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="h5" component="h2">
          {workspace.name}
        </Typography>
        <hr />

        {headCounts.map((head, index) => (
          <Grid container direction="row" justify="space-between">
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              {head}
            </Typography>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              {Object.values(counts)[index]}
            </Typography>
          </Grid>
        ))}
      </CardContent>
    </Card>
  );
};

export default WorkspaceDetails;
