import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const AuthGuard = ({ component: Component, user, canActivate, ...rest }) => {
  const checkCanActivate = () =>
    canActivate.includes(user.role) ? true : false;

  return (
    <Route
      {...rest}
      render={(props) =>
        user !== null && checkCanActivate() ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};
export default connect(mapStateToProps)(AuthGuard);
