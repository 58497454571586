import taskify from "../api/taskify";

export const srvAddTemplate = (workspaceId)=>{
    return taskify
       .post(`/templates/${workspaceId}`)
}

export const srvTemplateByUserId = ()=>{
    return taskify.get("/templates/templateWorkspace")
}

export const srvImportTemplate = (id,userId)=>{
    return taskify
      .get(`/templates/${id}/${userId}`)
}