import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import taskify from "../../api/taskify";
import InvitationForm from "../InvitationForm/InvitationForm";
import ModalComponent from "../modal/modal";
import User from "../user/user";

const UserListing = ({ user }) => {
  const [users, setUsers] = useState([]);
  const [teamMemberModal, setTeamMemberModal] = useState(false);

  useEffect(() => {
    taskify
      .get(`/users/getUsersByCompanyId/${user.companyId}`)
      .then((users) => {
        setUsers(users.data);
      })
      .catch((err) => console.log(err));
  }, [user]);

  const addTeamMember = (member) => {
    setTeamMemberModal(false);
  };

  const onTeamMemberModalClose = () => {
    setTeamMemberModal(false);
  };

  const onTeamMemberModalOpen = () => {
    setTeamMemberModal(true);
  };

  const displayUsers = () => {
    return users.map((user, index) => {
      return <User key={user.id} user={user} index={index} />;
    });
  };

  return (
    <div className="list-group">
      <h2>Users</h2>
      <Link
        color="secondary"
        variant="contained"
        onClick={onTeamMemberModalOpen}
        style={{ marginTop: 15, marginLeft: 15 }}
      >
        <p>Invite Member to Taskify </p>
      </Link>

      {users ? displayUsers() : null}

      <ModalComponent open={teamMemberModal}>
        <InvitationForm
          onSave={addTeamMember}
          handleClose={onTeamMemberModalClose}
        />
      </ModalComponent>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserListing);
