import React, { useState } from "react";
import { connect } from "react-redux";

import { srvAddClientResp } from "../../services/ClientServices";
import Button from "../button/Button";
import Input from "../input/Input";

const ClientRepForm = ({ closeModal, clientId,user }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [designation, setDesignation] = useState("");
  const [department, setDepartment] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [cellNo, setCellNo] = useState("");
  const [email, setEmail] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [updatedBy, setUpdatedBy] = useState("");


    const addClientResp = async (clientRepresentative) => {
    try{
      const res  = await srvAddClientResp(clientRepresentative)
      res();
        closeModal();
        alert("Client Representative Added");
    }
      catch(err){  console.log(err)};
 
  }  

  const onSave = (e) => {
    e.preventDefault();
    const createdBy = user.id;

    const clientRepresentative = {
      firstName,
      lastName,
      designation,
      department,
      phoneNo,
      cellNo,
      email,
      createdBy,
      clientId,
    };

    addClientResp(clientRepresentative);
    // taskify
    //   .post("/clientReps", { clientRepresentative })
    //   .then((data) => {
    //     closeModal();
    //     alert("Client Representative Added");
    //   })
    //   .catch((err) => console.log(err));
  };

  return (
    <form onSubmit={onSave}>
      <h2>Add Client Representative</h2>
      <Input
        fullWidth={true}
        variant="outlined"
        id="firstName"
        name="firstName"
        label="First Name"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
      />
      <Input
        fullWidth={true}
        variant="outlined"
        id="LastName"
        name="LastName"
        label="Last Name"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
      />

      <Input
        fullWidth={true}
        variant="outlined"
        id="designation"
        name="designation"
        label="Designation"
        value={designation}
        onChange={(e) => setDesignation(e.target.value)}
      />
      <Input
        fullWidth={true}
        variant="outlined"
        id="department"
        name="department"
        label="Department"
        value={department}
        onChange={(e) => setDepartment(e.target.value)}
      />

      <Input
        fullWidth={true}
        variant="outlined"
        id="phoneNo"
        name="phoneNo"
        label="Phone No"
        value={phoneNo}
        onChange={(e) => setPhoneNo(e.target.value)}
      />
      <Input
        fullWidth={true}
        variant="outlined"
        id="cellNo"
        name="cellNo"
        label="Cell No"
        value={cellNo}
        onChange={(e) => setCellNo(e.target.value)}
      />
      <Input
        fullWidth={true}
        variant="outlined"
        id="email"
        name="email"
        label="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Button onClick={onSave} color="primary" variant="contained">
        Save
      </Button>
    </form>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};
export default connect (mapStateToProps)(ClientRepForm)
