import { Grid, Paper, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Button from "../../components/button/Button";
import {
  asyncGetTemplatesByUserId,
  asyncImportTemplate,
} from "../../redux/actionCreators/templateActionCreators";
import { connect } from "react-redux";
import { asyncGetWorkSpaceByUserId } from "../../redux/actionCreators/workspaceActionCreators";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  container: {
    borderRadius: theme.spacing(2),
    border: "1px solid lightgrey",
    padding: theme.spacing(0, 0, 0, 0),
    margin: theme.spacing(0, 0, 10, 0),
  },
  heading: {
    padding: theme.spacing(2, 3, 2, 3),
    borderTopRightRadius: theme.spacing(2),
    borderTopLeftRadius: theme.spacing(2),
    marginBottom: theme.spacing(3),
    color: "white",
  },
}));

const Templates = ({
  history,
  getTemplates,
  templates,
  asyncImportTemplate,
  user,
  getWorkspaceByUserId,
}) => {
  const classes = useStyles();

  const [data, setData] = useState({
    templates: [],
  });

  useEffect(() => {
    getTemplates();
    setData((prevState) => {
      return { ...prevState, templates: templates };
    });
  }, []);

  const importTemplate = (id) => {
    asyncImportTemplate(id, user.id);

    history.push("/workspaceListing");
  };

  const displayTemplates = () => {
    // const { templates } = { ...data };

    return templates.length > 0 ? (
      templates.map((item) => {
        return (
          <React.Fragment>
            <Paper className={classes.paper}>
              <Grid container>
                <Grid container xs={10} alignItems="center">
                  <p style={{ margin: 0 }}>{item.name} </p>
                </Grid>
                <Grid container direction="row" justify="flex-end" xs={2}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => importTemplate(item.id)}
                  >
                    Import Template
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </React.Fragment>
        );
      })
    ) : (
      <div>
        <hr />
        <p>No templates to show currently</p>
      </div>
    );
  };

  return (
    <div>
      <Grid container alignItems="center" xs={6} justify="flex-start">
        <h5>My Templates</h5>
      </Grid>

      {displayTemplates()}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    templates: state.template.templates,
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTemplates: () => {
      dispatch(asyncGetTemplatesByUserId());
    },
    asyncImportTemplate: (id, userId) => {
      dispatch(asyncImportTemplate(id, userId));
    },
    getWorkspaceByUserId: (id) => {
      dispatch(asyncGetWorkSpaceByUserId(id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Templates);
