import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import { connect } from "react-redux";
import { srvAddTeamsMember } from "../../services/TeamServices";
import Button from "../button/Button";
import Input from "../input/Input";

const InvitationForm = ({ teams, user, handleClose }) => {
  const [email, setEmail] = useState();

  const AddteamMember = async (teamMember) => {
    try {
      const res = await srvAddTeamsMember(teamMember);
      alert("Team Member Added");
      handleClose();
    } catch (err) {
      console.log(err);
    }
  };

  const onSaveClicked = () => {
    if (!email) {
      alert("Email is Required");
      return;
    }
    const createdBy = user.id;
    const companyId = user.companyId;
    const roleId = user.roleId === 1 ? 2 : 3;

    const teamMember = {
      createdBy,
      email,
      companyId,
      roleId,
    };

    AddteamMember(teamMember);
  };

  return (
    <React.Fragment>
      <h2>Invite Team Member</h2>
      <Input
        fullWidth={true}
        variant="outlined"
        id="email"
        name="email"
        label="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />

      {/* <Dropdown
        fullWidth={true}
        name="teamId"
        label="Select Team"
        options={teams}
        onChange={(e) => setTeamId(e.target.value)}
      /> */}

      <Grid container justify="flex-end">
        <Button
          color="primary"
          variant="contained"
          style={{ marginTop: 2 }}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          style={{ marginTop: 2 }}
          onClick={onSaveClicked}
        >
          Send invite
        </Button>
      </Grid>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    teams: state.team.teams,
  };
};
export default connect(mapStateToProps)(InvitationForm);
