import {
  ADD_TEMPLATE,
  ADD_TEMPLATE_ERROR,
  GET_TEMPLATES_BY_USER_ID,
  IMPORT_TEMPLATE,
} from "../actionTypes/templateActionTypes";

const initialState = {
  templates: [],
  error: "",
};

export const templateReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TEMPLATE:
      return state;
    case GET_TEMPLATES_BY_USER_ID:
      return {
        ...state,
        templates: action.payload.templates,
      };
    case ADD_TEMPLATE_ERROR:
      return { ...state, error: action.payload.error };
    case IMPORT_TEMPLATE:
      return state;
    default:
      return state;
  }
};
