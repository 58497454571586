import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import taskify from "../../api/taskify";
import UserTask from "../userTask/userTask";
import moment from "moment";
import { Paper } from "@material-ui/core";

const UserTaskList = ({ user, tasks, updateTaskStatus, getTaskStats }) => {
  const [distinctDates, setDistinctDates] = useState([]);

  useEffect(() => {
    taskify
      .get(`/tasks/getDueDatesByUserId/${user.id}`)
      .then((res) => {
        let dates = [];
        let distinctDates = [];

        res.data.forEach((d) => {
          const date = moment(d.dueDate).format("MM/DD/YYYY");
          dates.push(date);
        });

        dates.map((d) => {
          if (!distinctDates.includes(d)) {
            distinctDates.push(d);
          }
        });

        setDistinctDates(distinctDates);
      })
      .catch((err) => console.log(err));
  }, [user]);

  const displayTasks = () => {
    return distinctDates.map((date) => (
      <div style={{ marginTop: "2%" }}>
        <h2 style={{ paddingLeft: "7%", fontWeight: "bold" }}>{date}</h2>
        {tasks.map((task) => {
          return moment(task.dueDate).format("MM/DD/YYYY") === date ? (
            <UserTask
              key={task.id}
              task={task}
              updateTaskStatus={updateTaskStatus}
              showStatus={true}
            />
          ) : null;
        })}
      </div>
    ));
  };

  return tasks.length > 0 ? displayTasks() : null;
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(UserTaskList);
