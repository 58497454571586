import taskify from "../../api/taskify";
import { srvAddTasks } from "../../services/TaskServices";
import { srvTasksByUserId } from "../../services/TaskServices";
import {
  ADD_COMMNENT,
  ADD_DOCUMENT,
  ADD_TASK,
  GET_TASKS_BY_USER_ID,
} from "../actionTypes/taskActionTypes";

export const getTasksByUserId = (tasks) => {
  return {
    type: GET_TASKS_BY_USER_ID,
    payload: { tasks },
  };
};

export const asyncGetTasksByUserId = (id) => {
  return async (dispatch) => {
    try {
      const TaskByUserId = await srvTasksByUserId(id);
      dispatch(getTasksByUserId(TaskByUserId.data));
    } catch (err) {
      console.log(err);
    }
  };
};

export const addTask = (task) => {
  return {
    type: ADD_TASK,
    payload: { task },
  };
};

export const asyncAddTask = (task) => {
  return async (dispatch) => {
    try {
      const res = await srvAddTasks(task);
      dispatch(addTask(res.data));
    } catch (err) {
      console.log(err);
    }
  };
};

export const asyncTaskStatusUpdate = (task, updatedId) => {
  return (dispatch) => {
    taskify
      .put(`/tasks/${task.id}`, { taskStatusId: updatedId })
      .then((res) => {
        dispatch(asyncGetTasksByUserId(task.userId));
      })
      .catch((err) => {});
  };
};

export const addTaskComment = (comment) => {
  return {
    type: ADD_COMMNENT,
    payload: { comment },
  };
};

export const addTaskDocument = (document) => {
  return {
    type: ADD_DOCUMENT,
    payload: { document },
  };
};
