import React, { useState, useEffect } from "react";
import { makeStyles, Paper, Grid, Typography } from "@material-ui/core";
import { Table } from "react-bootstrap";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    margin: 25,
    overflow: "scroll",
  },
  container: {
    width: "100%",
    borderRadius: theme.spacing(2),
    border: "1px solid lightgrey",
    padding: theme.spacing(0, 0, 0, 0),
    margin: theme.spacing(0, 0, 10, 0),
  },
  heading: {
    padding: theme.spacing(2, 3, 2, 3),
    borderTopRightRadius: theme.spacing(2),
    borderTopLeftRadius: theme.spacing(2),
    marginBottom: theme.spacing(3),
    color: "white",
  },
}));

const TaskGrid = ({ tasks, header }) => {
  const classes = useStyles();

  return (
    <Grid container xs={6}>
      <Paper className={classes.paper}>
        <Typography
          style={{ background: "linear-gradient(60deg,#292E49,#BBD2C5)" }}
          className={classes.heading}
          variant="h6"
        >
          {header}
        </Typography>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Priority</th>
              <th>Status</th>
              <th>Due Date</th>
            </tr>
          </thead>
          <tbody>
            {tasks &&
              tasks.map((item, index) => {
                return (
                  <tr>
                    <td>{item.name}</td>
                    <td>
                      {item["priorityId"] === 1
                        ? "Low"
                        : item["priorityId"] === 2
                        ? "Medium"
                        : item["priorityId"] === 3
                        ? "High"
                        : null}{" "}
                    </td>
                    <td>
                      {item["taskStatusId"] === 1
                        ? "Assigned"
                        : item["taskStatusId"] === 2
                        ? "InProgress"
                        : item["taskStatusId"] === 3
                        ? "Done"
                        : null}
                    </td>
                    <td>
                      {moment(item.dueDate).format("MM/DD/YYYY h:mm:ss a")}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Paper>
    </Grid>
  );
};
export default TaskGrid;
