import React, { useState, useEffect } from "react";
import Input from "../input/Input";
import Button from "../button/Button";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { asyncAddWorkSpace } from "../../redux/actionCreators/workspaceActionCreators";
import taskify from "../../api/taskify";
import DropDown from "../dropdown/Dropdown";

const AddWorkspace = ({ onSave, addWorkspace, user, onCancel }) => {
  const [name, setName] = useState("");
  const [teams, setTeams] = useState([]);
  const [teamId, setTeamId] = useState();

  useEffect(() => {
    taskify
      .get(`teams/getTeamsByCompany/${user.companyId}`)
      .then((res) => setTeams(res.data))
      .catch((err) => console.log(err));
  }, [user]);

  const onSaveClick = async (e) => {
    e.preventDefault();
    const payload = {
      name: name || "Workspace",
      userId: user.id,
      teamId: teamId,
    };
    onSave(payload);
    addWorkspace(payload);
  };

  return (
    <div>
      <h2>Add Workspace</h2>
      <Input
        fullWidth={true}
        variant="outlined"
        id="ws-name"
        name="ws-name"
        label="Workspace Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />

      <DropDown
        fullWidth={true}
        name="t-assign"
        label="Assign Team"
        options={teams}
        onChange={(e) => setTeamId(e.target.value)}
      />

      <Grid container justify="flex-end">
        <Button onClick={onCancel} color="primary" variant="contained">
          Cancel
        </Button>
        <Button onClick={onSaveClick} color="primary" variant="contained">
          Save
        </Button>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addWorkspace: (workspace) => {
      dispatch(asyncAddWorkSpace(workspace));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddWorkspace);
