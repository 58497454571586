import { Avatar, Grid, ListItemAvatar } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Stage, Layer } from "react-konva";
import ButtonComponent from "../button/Button";
import Circ from "../circle/Circle";
import taskify from "../../api/taskify";
import Input from "../input/Input";
import { connect } from "react-redux";

const ImageEditor = ({ img, doc, user, closeModal }) => {
  const [rectangles, setRectangles] = useState([]);
  const [circles, setCircles] = useState(doc.markings || []);
  const [images, setImages] = useState([]);
  const [selectedId, selectShape] = useState(null);
  const [shapes, setShapes] = useState([]);
  const [, updateState] = React.useState();
  const stageEl = React.createRef();
  const layerEl = React.createRef();
  const fileUploadEl = React.createRef();
  const [editImage, setEditImage] = useState(false);
  const [circleId, setCircleId] = useState(0);
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState("");

  const addCircle = (x, y) => {
    taskify
      .post("/markings", {
        x,
        y,
        width: 25,
        height: 25,
        fill: "red",
        documentId: doc.id,
      })
      .then((res) => {
        const circs = circles.concat([res.data]);
        setCircles(circs);
        const shs = shapes.concat([`circ${circles.length + 1}`]);
        setShapes(shs);
      })
      .catch((err) => console.log(err));
    // const circ = {
    //   x: x,
    //   y: y,
    //   width: 25,
    //   height: 25,
    //   fill: "red",
    //   id: `circ${circles.length + 1}`,
    // };
  };

  const eraseLine = () => {
    // addLine(stageEl.current.getStage(), layerEl.current, "erase");
  };

  const forceUpdate = React.useCallback(() => updateState({}), []);

  const fileChange = (ev) => {
    let file = ev.target.files[0];
    let reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        const id = Math.random();
        images.push({
          content: reader.result,
          id,
        });
        setImages(images);
        fileUploadEl.current.value = null;
        shapes.push(id);
        setShapes(shapes);
        forceUpdate();
      },
      false
    );
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const undo = () => {
    const lastId = shapes[shapes.length - 1];
    let index = circles.findIndex((c) => c.id === lastId);
    if (index !== -1) {
      circles.splice(index, 1);
      setCircles(circles);
    }
    index = rectangles.findIndex((r) => r.id === lastId);
    if (index !== -1) {
      rectangles.splice(index, 1);
      setRectangles(rectangles);
    }
    index = images.findIndex((r) => r.id === lastId);
    if (index !== -1) {
      images.splice(index, 1);
      setImages(images);
    }
    shapes.pop();
    setShapes(shapes);
    forceUpdate();
  };

  document.addEventListener("keydown", (ev) => {
    if (ev.code === "Delete") {
      let index = circles.findIndex((c) => c.id === selectedId);
      if (index !== -1) {
        circles.splice(index, 1);
        setCircles(circles);
      }
      index = images.findIndex((r) => r.id === selectedId);
      if (index !== -1) {
        images.splice(index, 1);
        setImages(images);
      }
      forceUpdate();
    }
  });

  const getCommentsByCircleId = (circleId) => {
    taskify
      .get(`/markingComments/${circleId}`)
      .then((res) => {
        console.log(res.data, "circi");
        setComments(res.data);
      })
      .catch((err) => console.log(err));
  };
  const onSave = () => {
    taskify
      .post("/markingComments", {
        comment: comment,
        userId: user.id,
        markingId: circleId,
      })
      .then((res) => {
        res.data.user = { fName: user.fName };
        setComments([...comments, res.data]);
        setComment("");
      })
      .catch((err) => console.log(err));
  };

  return (
    <div style={{ height: "94vh" }}>
      <Grid container justify="flex-end">
        <ButtonComponent
          color="primary"
          variant="contained"
          onClick={closeModal}
        >
          Close
        </ButtonComponent>
        <ButtonComponent
          color="primary"
          variant="contained"
          onClick={() => setEditImage(!editImage)}
        >
          {!editImage ? <span> Edit Image </span> : <span>Stop Editing</span>}
        </ButtonComponent>
      </Grid>
      <div
        className="home-page"
        style={{
          backgroundImage: `url(${img})`,
          backgroundSize: "100% 100%",
          overflow: "hidden",
          backgroundRepeat: "no-repeat",
          width: 650,
          height: 450,
        }}
        onMouseDown={(e) => {
          if (editImage)
            addCircle(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
        }}
      >
        <Stage
          width={650}
          height={450}
          ref={stageEl}
          onMouseDown={(e) => {
            const clickedOnEmpty = e.target === e.target.getStage();
            if (clickedOnEmpty) {
              selectShape(null);
            }
          }}
        >
          <Layer ref={layerEl}>
            {circles.map((circle, i) => {
              return (
                <Circ
                  key={i}
                  shapeProps={circle}
                  isSelected={circle.id === selectedId}
                  onSelect={() => {
                    selectShape(circle.id);
                  }}
                  onChange={(newAttrs) => {
                    const circs = circles.slice();
                    circs[i] = newAttrs;
                    setCircles(circs);
                  }}
                  onClick={() => {
                    getCommentsByCircleId(circle.id);
                    setCircleId(circle.id);
                  }}
                />
              );
            })}
          </Layer>
        </Stage>
      </div>
      {circleId > 0 && comments.length > 0 ? (
        <div>
          {comments.map((comment) => {
            return (
              <React.Fragment>
                <Grid container align="space-between" style={{ paddingTop: 5 }}>
                  <ListItemAvatar>
                    <Avatar
                      alt={comment.user.fName}
                      style={{ backgroundColor: "purple" }}
                      src="/static/images/avatar/1.jpg"
                    />
                  </ListItemAvatar>
                  <p style={{ paddingTop: 8 }}>{comment.comment}</p>
                </Grid>
              </React.Fragment>
            );
          })}
          <Input
            fullWidth={true}
            variant="outlined"
            id="comment"
            name="comment"
            label="Add Comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </div>
      ) : null}

      {circleId > 0 && comments.length <= 0 ? (
        <Input
          fullWidth={true}
          variant="outlined"
          id="comment"
          name="Comment"
          label="Add Comment"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      ) : null}
      {circleId ? (
        <Grid container justify="flex-end">
          <ButtonComponent onClick={onSave} color="primary" variant="contained">
            Save
          </ButtonComponent>
        </Grid>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};
export default connect(mapStateToProps)(ImageEditor);
