import React, { memo } from "react";
import MuiPhoneNumber from "material-ui-phone-number";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const PhoneInputField = ({ value, onChange }) => {
  return (
    <div className="form-group">
      {console.log("Phone Component Rendered")}
      <MuiPhoneNumber
        fullWidth={true}
        label="Phone Number"
        placeholder="Phone Number"
        variant="outlined"
        defaultCountry={"pk"}
        onChange={onChange}
      />
    </div>
  );
};

export default memo(PhoneInputField);
