import taskify from "../api/taskify";

export const srvTasksByUserId = (id) => {
  return taskify.get(`/tasks/getTasksByUserId/${id}`);
};

export const srvAddTasks = (task) => {
  return taskify.post("/tasks", task);
};

export const srvAssignTask = (companyId) => {
  return taskify.get(`teamMembers/company/${companyId}`);
};

export const srvassignTask = (taskId, dueDate, userId) => {
  return taskify.put(`/tasks/${taskId}`, { dueDate, userId });
};

export const srvAddDocument = (data) => {
  return taskify.post("/documents", data);
};

export const srvAddTaskComments = (comment) => {
  return taskify.post("/taskComments", comment);
};
