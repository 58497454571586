import React, { useState, useCallback, useEffect } from "react";
import taskify from "../../api/taskify";
import ButtonComponent from "../../components/button/Button";
import Input from "../../components/input/Input";
import DropDown from "../../components/dropdown/Dropdown";
import { validation, validate } from "../../utils/inputValidation";
import Joi from "joi-browser";
import PhoneInputField from "../../components/phoneInput/phoneInput";
import { Grid, Paper, makeStyles, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { image } from "faker";

const useStyles = makeStyles((theme) => ({
  div: {
    minHeight: "100vh",
  },
  width: {
    minHeight: "300px",
    width: "35%",
    padding: theme.spacing(6),
    margin: theme.spacing(3),
  },
  cent: {
    textAlign: "center",
    marginBottom: theme.spacing(3),
    fontWeight: "bold",
  },
}));

const Register = ({ history }) => {
  const classes = useStyles();

  const [invitedEmail, setInvitedEmail] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [file, setFile] = useState("");

  const [form, setForm] = useState({
    fName: "",
    lName: "",
    email: invitedEmail || "",
    password: "",
    countryId: 1,
    company: companyId || "",
  });

  const [errors, setError] = useState({});
  const [contact, setContact] = useState();

  const schema = {
    fName: Joi.string().min(4).required().label("First Name"),
    lName: Joi.string().min(4).required().label("Last Name"),
    email: Joi.string().email().min(4).required().label("Email"),
    password: Joi.string().min(8).required().label("Password"),
    countryId: Joi.number().required().label("Country"),
    company: Joi.string(),
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("decoded"));
    if (user) {
      setForm((prevState) => {
        return { ...prevState, email: user.email, company: user.company };
      });
      setInvitedEmail(user.email);
      setCompanyId(user.companyId);
    }
  }, []);

  const handleContact = useCallback((contact) => {
    setContact(contact);
  }, []);

  const fileHandler = (e) => {
    const file = e.target.files[0];
    setFile(file);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const regForm = { ...form };
    // regForm["contact"] = contact;
    // regForm["isActive"] = 1;
    // regForm["profileImg"] = file.name
    const data = new FormData();
    data.append("fName", regForm.fName);
    data.append("lName", regForm.lName);
    data.append("email", regForm.email);
    data.append("password", regForm.password);
    data.append("countryId", regForm.countryId);
    data.append("contact", contact);
    data.append("isActive", 1);
    data.append("profileImg", file.name);
    // data.append("file", file);
    if (!invitedEmail) {
      // regForm["roleId"] = 1;
      data.append("company", regForm.company);
      data.append("roleId", 1);
      taskify
        .post("/users", data)
        .then((data) => {
          alert("Registered Successfully");
          history.push("/login");
        })
        .catch((err) => setError({ resEmail: "Email Already Exists" }));
    } else {
      taskify
        .put(`/users/register/invited/${regForm.email}`, data)
        .then((res) => {
          alert("Registered Successfully");
          history.push("/login");
        })
        .catch((err) => console.log(err));
    }
    localStorage.clear(["decoded", "token"]);
  };

  const handleChange = ({ target: input }) => {
    const { name } = input;
    const errors = validation(input, schema);
    const newFormState = { ...form };
    newFormState[name] = input.value;
    setForm(newFormState);
    setError(errors);
  };

  return (
    <Grid
      container
      className={classes.div}
      justify="center"
      alignItems="center"
    >
      <Paper className={classes.width}>
        <form>
          <Typography color="primary" variant="h4" className={classes.cent}>
            taskify
          </Typography>
          <h4>Signup</h4>
          {errors.resEmail ? (
            <p className="alert alert-danger">Email Already Exists</p>
          ) : null}
          <Input
            fullWidth={true}
            variant="outlined"
            id="fName"
            name="fName"
            label="First Name"
            value={form.fName}
            onChange={handleChange}
            error={errors ? errors.fName : null}
          />
          <Input
            fullWidth={true}
            variant="outlined"
            id="lName"
            name="lName"
            label="Last Name"
            value={form.lName}
            onChange={handleChange}
            error={errors ? errors.lName : null}
          />
          <Input
            fullWidth={true}
            variant="outlined"
            id="email"
            name="email"
            label="Email"
            disabled={invitedEmail}
            value={form.email}
            onChange={handleChange}
            error={errors ? errors.email : null}
          />
          <Input
            fullWidth={true}
            placeholder="Password"
            variant="outlined"
            id="password"
            name="password"
            type="password"
            label="Password"
            value={form.password}
            onChange={handleChange}
            error={errors ? errors.password : null}
          />
          <Input
            fullWidth={true}
            placeholder="Company"
            variant="outlined"
            id="company"
            name="company"
            label="company"
            value={form.company}
            onChange={handleChange}
            disabled={companyId}
            error={errors ? errors.password : null}
          />

          <DropDown
            fullWidth={true}
            name="countryId"
            label="Country"
            options={[
              { id: 1, name: "Pakistan" },
              { id: 2, name: "India" },
              { id: 3, name: "USA" },
            ]}
            onChange={handleChange}
          />
          <PhoneInputField onChange={handleContact} />
          <Input
            type="file"
            fullWidth={true}
            variant="outlined"
            id="filename"
            name="file"
            onChange={fileHandler}
          />
          <ButtonComponent
            onClick={handleSubmit}
            color="primary"
            disabled={validate(form, schema)}
            variant="contained"
            style={{ marginLeft: 0, marginTop: 4, width: "100%" }}
          >
            Register
          </ButtonComponent>
          <ButtonComponent
            onClick={() => history.push("/login")}
            color="primary"
            variant="contained"
            style={{ marginLeft: 0, marginTop: 4, width: "100%" }}
          >
            Login
          </ButtonComponent>
        </form>
      </Paper>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(Register);
