import { Grid, Paper } from "@material-ui/core";
import React from "react";

const Team = ({ team, index }) => {
  return (
    <Paper style={{ paddingTop: 8, marginTop: 5 }}>
      <Grid container direction="row" justify="space-evenly" align="center">
        <p style={{ paddingLeft: 14 }}>{`${index + 1}.`}</p>
        <Grid item xs>
          <p>{team.name}</p>
        </Grid>
        <Grid item xs>
          <p>{team.description}</p>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Team;
