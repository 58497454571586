import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import ModalComponent from "../modal/modal";
import ImageEditor from "../ImageEditor/ImageEditor";
// import ImageEditor from "../ImageEditor/ImageEditor";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "36ch",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
}));

const Comment = ({ comment }) => {
  const classes = useStyles();
  const colors = ["red", "yellow", "purple", "black", "blue"];
  const [imageModal, setImageModal] = useState(false);
  const [image, setImage] = useState();
  const [doc, setDoc] = useState();

  return (
    <div>
      <List className={classes.root}>
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar
              alt={comment.user.fName}
              style={{ backgroundColor: colors[2] }}
              src="/static/images/avatar/1.jpg"
            />
          </ListItemAvatar>
          <ListItemText
            primary={`${comment.user.fName} ${comment.user.lName}`}
            secondary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.inline}
                  color="textPrimary"
                >
                  <p
                    onMouseDown={(e) => {
                      console.log(
                        e.nativeEvent.offsetX,
                        e.nativeEvent.offsetY,
                        "stringi"
                      );
                    }}
                  >
                    {comment.comment}
                  </p>
                </Typography>

                <Typography
                  component="span"
                  variant="body2"
                  className={classes.inline}
                  color="textPrimary"
                >
                  {comment.documents
                    ? comment.documents.map((doc) =>
                        doc.name.includes("jpg") ||
                        doc.name.includes("jpeg") ||
                        doc.name.includes("png") ? (
                          <Link
                            onClick={() => {
                              setImage(
                                `http://124.29.232.144:3001/${doc.name}`
                              );
                              setDoc(doc);
                              setImageModal(true);
                            }}
                          >
                            {doc.name}
                          </Link>
                        ) : (
                          <a
                            key={doc.id}
                            rel="noopener noreferrer"
                            target="_blank"
                            href={`http://124.29.232.144:3001/${doc.name}`}
                          >
                            {doc.name}
                          </a>
                        )
                      )
                    : null}
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
        <Divider variant="inset" component="li" />
      </List>

      <ModalComponent open={imageModal}>
        <ImageEditor
          img={image}
          doc={doc}
          closeModal={() => setImageModal(false)}
        />
      </ModalComponent>
    </div>
  );
};

export default Comment;
