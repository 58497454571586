import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { authReducer } from "./reducers/authReducer";
import { workspaceReducer } from "./reducers/workspaceReducer";
import { templateReducer } from "./reducers/templateReducer";
import { priorityReducer } from "./reducers/priorityReducer";
import { memberReducer } from "./reducers/memberReducer";
import { teamReducer } from "./reducers/teamReducer";
import { clientReducer } from "./reducers/clientReducer";
import { taskReducer } from "./reducers/taskReducer";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  auth: authReducer,
  workspace: workspaceReducer,
  template: templateReducer,
  priority: priorityReducer,
  teamMember: memberReducer,
  team: teamReducer,
  client: clientReducer,
  task: taskReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunk))
);
const persistor = persistStore(store);

export { store, persistor };
