import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import taskify from "../../api/taskify";
import Button from "../button/Button";
import Input from "../input/Input";
import {
  addTaskComment,
  addTaskDocument,
} from "../../redux/actionCreators/taskActionCreators";
import Comment from "../comment/Comment";
import { Link } from "react-router-dom";

const TaskDetails = ({ user, task, closeModal }) => {
  const [taskState, setTaskState] = useState(task);
  const [file, setFile] = useState("");
  const [commentText, setCommentText] = useState("");
  const [expand, setExpand] = useState(false);

  const onSaveClick = (e) => {
    e.preventDefault();

    const newTaskState = { ...taskState };

    // if (commentText.length > 0) {
    const comment = {
      taskId: task.id,
      userId: user.id,
      comment: commentText,
      // documents: [],
    };

    taskify
      .post("/taskComments", { comment })
      .then((comment) => {
        comment.data.user = {
          fName: user.fName,
          lName: user.lName,
        };
        // comment.data.documents = file !== "" ? [{ name: file.name }] : null;

        setCommentText("");

        if (file !== "") {
          const data = new FormData();
          data.append("taskCommentId", comment.data.id);
          data.append("taskId", task.id);
          data.append("file", file);

          taskify
            .post(`/documents`, data)
            .then((res) => {
              comment.data.documents = [res.data];
              newTaskState.comments.push(comment.data);
              setFile("");
            })
            .catch((err) => console.log(err));
        } else {
          newTaskState.comments.push(comment.data);
        }
        setTaskState({ ...newTaskState });
      })
      .catch((err) => console.log(err));
  };

  const displayComments = () => {
    const numOfComments = expand ? taskState.comments.length : 5;

    return (
      <div>
        {taskState.comments ? (
          taskState.comments.slice(0, numOfComments).map((comment) => {
            return <Comment comment={comment} key={comment.id} />;
          })
        ) : (
          <p>No Comments to Show</p>
        )}
        {(taskState.comments.length > 0) & (taskState.comments.length > 5) ? (
          <Link onClick={() => setExpand(!expand)}>
            View
            {expand ? <span> Less </span> : <span> More </span>}
            comments
          </Link>
        ) : null}
      </div>
    );
  };

  const displayDocuments = () => {
    return taskState.documents.map((document) => {
      return (
        <Grid container direction="column">
          <a
            key={document.id}
            rel="noopener noreferrer"
            target="_blank"
            href={`http://124.29.232.144:3001/${document.name}`}
          >
            {document.name}
          </a>
        </Grid>
      );
    });
  };

  const fileHandler = (e) => {
    const file = e.target.files[0];
    setFile(file);
  };

  return (
    <div
      style={{
        overflowY: true,
        // height: user.role === "User" ? "100%" : "85vh",
        height: "100%",
      }}
    >
      <h3>Comments</h3>
      {displayComments()}

      <Input
        type="file"
        fullWidth={true}
        variant="outlined"
        id="filename"
        name="file"
        onChange={fileHandler}
      />
      <Input
        fullWidth={true}
        variant="outlined"
        id="comment"
        name="comment"
        label="Add Comment"
        value={commentText}
        onChange={(e) => {
          setCommentText(e.target.value);
        }}
      />
      <Grid container justify="flex-end" style={{ marginBottom: 5 }}>
        <Button onClick={onSaveClick} color="primary" variant="contained">
          Save
        </Button>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addComment: (comment) => {
      dispatch(addTaskComment(comment));
    },
    addDocument: (document) => {
      dispatch(addTaskDocument(document));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskDetails);
