import {
  ADD_TEAM,
  ADD_TEAM_FAILED,
  GET_TEAMS_BY_COMPANY,
} from "../actionTypes/teamActionType";

const initialState = {
  teams: [],
  error: "",
};

export const teamReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TEAM:
      return {
        ...state,
        teams: [...state.teams, action.payload.team],
      };
    case GET_TEAMS_BY_COMPANY:
      return {
        ...state,
        teams: action.payload.teams,
      };
    case ADD_TEAM_FAILED:
      return {
        ...state,
        error: action.payload.error,
        teams: state.teams.slice(0, state.teams.length),
      };
    default:
      return state;
  }
};
