import React, { useEffect, useState } from "react";
import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { asyncTaskStatusUpdate } from "../../redux/actionCreators/taskActionCreators";
import Dropdown from "../dropdown/Dropdown";
import TaskDetails from "../taskDetails/TaskDetails";
import moment from "moment";
import Priority from "../priority/PriorityComponent";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  container: {
    borderRadius: theme.spacing(2),
    border: "1px solid lightgrey",
    padding: theme.spacing(0, 0, 0, 0),
    margin: theme.spacing(0, 0, 10, 0),
  },
  heading: {
    padding: theme.spacing(2, 3, 2, 3),
    borderTopRightRadius: theme.spacing(2),
    borderTopLeftRadius: theme.spacing(2),
    marginBottom: theme.spacing(3),
    color: "white",
  },
  taskName: {
    fontSize: 28,
  },
  description: {
    fontSize: 20,
  },
  dueDateHead: {
    fontSize: 18,
  },
  priority: {
    fontSize: 16,
  },
}));

const UserTaskModal = ({
  task,
  status,
  updateStatus,
  closeModal,
  showStatus,
}) => {
  const classes = useStyles();

  return (
    <div className="container">
      {/* <h2> {task.group.workspace.name}</h2> */}
      <Grid
        container
        direction="row"
        alignItems="center"
        justify="space-between"
      >
        <p className={classes.taskName}>{task.name}</p>
        <p>
          <span className={classes.dueDateHead}>Due Date: </span>
          {moment(task.dueDate).format("MM/DD/YYYY h:mm:ss a")}
        </p>
      </Grid>
      <hr />
      <Priority name={task.priority.name} />
      <p className={classes.description}>{task.description}</p>

      {showStatus ? (
        <Dropdown
          fullWidth={true}
          name="status"
          label="Status"
          options={status}
          selected={task.taskStatusId}
          onChange={(e) => {
            updateStatus(task, e.target.value);
          }}
        />
      ) : null}
      <div style={{ paddingBottom: 5 }}>
        <TaskDetails task={task} closeModal={closeModal} />
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateTaskStatus: (task, updatedId) => {
      dispatch(asyncTaskStatusUpdate(task, updatedId));
    },
  };
};
export default connect(null, mapDispatchToProps)(UserTaskModal);
