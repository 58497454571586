import {srvClientsByUserId} from "../../services/ClientServices"
import {srvAddClients} from "../../services/ClientServices"
import {
  ADD_CLIENT,
  GET_CLIENTS_BY_USER_ID,
} from "../actionTypes/clientActionTypes";

export const getClientsByUserId = (clients) => {
  return {
    type: GET_CLIENTS_BY_USER_ID,
    payload: { clients },
  };
};

export const asyncGetClientsByUserId = (id) => {
  return async (dispatch) => {
  try{
    const clients =  await  srvClientsByUserId(id)
        dispatch(getClientsByUserId(clients.data));
  }
  catch(err){
      console.log(err)
  } 
  };
};

export const addClient = (client) => {
  return {
    type: ADD_CLIENT,
    payload: { client },
  };
};


export const asyncAddClient = (client) => {
  return async (dispatch) => {
    try{
      const res = await srvAddClients(client)
      dispatch(addClient(res.data));
    }
    catch(err){console.log(err)}
  };
};
