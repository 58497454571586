import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import taskify from "../../api/taskify";
import { Checkbox, Grid } from "@material-ui/core";
import Input from "../input/Input";
import Button from "../button/Button";

const CreateTeam = ({ user, onCreateNewTeam, onClose }) => {
  const [members, setMembers] = useState([]);
  const [team, setTeam] = useState([]);
  const [name, setName] = useState([]);
  const [description, setDescription] = useState([]);

  useEffect(() => {
    taskify
      .get(`/users/getUsersByCompanyId/${user.companyId}`)
      .then((res) => setMembers(res.data))
      .catch((err) => console.log(err));
  }, [user]);

  const updateTeam = (index) => {
    const member = members[index];

    const isTeamMember = team.find((m) => m.id === member.id);

    if (isTeamMember) {
      const newTeam = [...team];
      setTeam(newTeam.filter((teamMember) => teamMember.id !== member.id));
    } else {
      setTeam([...team, member]);
    }
  };

  const onSave = () => {
    const payload = {
      name,
      description,
      team,
      createdBy: user.id,
      companyId: user.companyId,
    };

    taskify
      .post("/teams", payload)
      .then((res) => {
        onCreateNewTeam(res.data);
      })
      .catch();
  };

  const displayMembers = () => {
    return members.map((member, index) => {
      return (
        <div>
          <Checkbox
            key={member.id}
            onChange={() => {
              updateTeam(index);
            }}
          />
          {member.fName} {member.lName}
        </div>
      );
    });
  };

  return (
    <div>
      <h4>Create Team</h4>
      <Input
        fullWidth={true}
        variant="outlined"
        id="team-name"
        name="team-name"
        label="Team Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <Input
        fullWidth={true}
        variant="outlined"
        id="team-description"
        name="team-description"
        label="Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <h6 style={{ fontWeight: "bold" }}>Select Team Members</h6>
      <hr />
      {members.length > 0 ? displayMembers() : null}

      <Grid container justify="flex-end">
        <Button color="primary" onClick={onClose} variant="contained">
          Close
        </Button>
        <Button color="primary" onClick={onSave} variant="contained">
          Save Team
        </Button>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};
export default connect(mapStateToProps)(CreateTeam);
