import React, { useState, useEffect } from "react";
import Joi from "joi-browser";
import { validation, validate } from "../../utils/inputValidation";
import Input from "../../components/input/Input";
import ButtonComponent from "../../components/button/Button";
import { Grid, Paper, makeStyles, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { asyncLogin } from "../../redux/actionCreators/authActionCreators";
import Loader from "../../components/spinner/Spinner";
import LoginImage from "../../assets/images/login-page.svg";

const useStyles = makeStyles((theme) => ({
  div: {
    height: "99vh",
  },
  width: {
    minHeight: "475px",
    width: "25%",
    padding: theme.spacing(10),
  },
  cent: {
    textAlign: "center",
    marginBottom: theme.spacing(3),
    fontWeight: "bold",
  },
}));

const Login = ({ login, loginError, user, history }) => {
  const classes = useStyles();
  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const [showSpinner, setShowSpinner] = useState(false);
  const [errors, setError] = useState({});
  const [img, setImg] = useState();

  const schema = {
    email: Joi.string().email().min(5).required().label("Email"),
    password: Joi.string().min(5).required().label("Password"),
  };

  const backgroundImages = [
    require("../../assets/images/logintask.svg"),
    require("../../assets/images/image-2.png"),
    require("../../assets/images/image-3.png"),
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = { email: form.email, password: form.password };
    login(payload);
    setShowSpinner(true);
  };

  const isLoggedIn = () => {
    history.replace("/dashboard");
  };

  const handleChange = ({ currentTarget: input }) => {
    const { name } = input;
    const errors = validation(input, schema);
    const newFormState = { ...form };
    newFormState[name] = input.value;
    setForm(newFormState);
    setError(errors);
  };

  const displayLoginError = loginError ? (
    <p className="alert alert-danger">Invalid Email or Password</p>
  ) : null;

  const loader =
    showSpinner && !loginError ? (
      <div>
        <Loader />
        <span className="sr-only">Loading...</span>
      </div>
    ) : (
      <span>Login</span>
    );

  useEffect(() => {
    setImg(Math.floor(Math.random() * 3));
  }, []);

  return (
    <div
      style={
        {
          // backgroundImage: backgroundImages[0]),
        }
      }
    >
      <div
        style={{
          marginTop: "5%",
        }}
      >
        {user !== null ? isLoggedIn() : null}
        <Grid container direction="row">
          <img
            alt="login"
            src={backgroundImages[img]}
            width="65%"
            height="450"
          />

          <Paper className={classes.width} style={{ marginRight: 40 }}>
            <Grid container direction="column">
              <form>
                <Typography
                  color="primary"
                  variant="h4"
                  className={classes.cent}
                >
                  taskify
                </Typography>
                <h4 style={{ marginLeft: "14%" }}>Welcome Back :)</h4>
                {displayLoginError}
                <Input
                  fullWidth={true}
                  variant="outlined"
                  id="email"
                  name="email"
                  label="Email"
                  value={form.email}
                  onChange={handleChange}
                  error={errors ? errors.email : null}
                />
                <Input
                  fullWidth={true}
                  variant="outlined"
                  id="password"
                  name="password"
                  label="Password"
                  type="password"
                  value={form.password}
                  onChange={handleChange}
                  error={errors ? errors.password : null}
                />
                <ButtonComponent
                  onClick={handleSubmit}
                  disabled={validate(form, schema)}
                  color="primary"
                  variant="contained"
                  style={{ marginLeft: 0, width: "100%" }}
                >
                  {loader}
                </ButtonComponent>
                <ButtonComponent
                  onClick={() => history.push("/register")}
                  color="primary"
                  variant="contained"
                  style={{ marginLeft: 0, marginTop: 4, width: "100%" }}
                >
                  Sign Up
                </ButtonComponent>
              </form>
            </Grid>
          </Paper>
        </Grid>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    loginError: state.auth.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (user) => {
      dispatch(asyncLogin(user));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
