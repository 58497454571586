const { GET_PRIORITY } = require("../actionTypes/priorityActionTypes");

const initialState = {
  priorities: [],
  error: "",
};

export const priorityReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRIORITY:
      return {
        ...state,
        priorities: action.payload.priorities,
      };
    default:
      return state;
  }
};
