import React, { useEffect, useState } from "react";
import { Checkbox, Grid } from "@material-ui/core";
import Input from "../input/Input";
import Button from "../button/Button";
import DropDown from "../dropdown/Dropdown";
import taskify from "../../api/taskify";
import { connect } from "react-redux";
import { addTask } from "../../redux/actionCreators/taskActionCreators";
import moment from "moment";
import DateTime from "../../components/dateTimePicker/dateTime";

const Task = ({
  workspaceId,
  teamMembers,
  selectedGroupId,
  onSave,
  closeTaskModal,
  user,
  priority,
  add,
  editTask,
  onEdit,
}) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [label, setLabel] = useState("");
  const [priorityId, setPriorityId] = useState(1);
  const [taskStatusId, setTaskStatusId] = useState(1);
  const [createdBy, setCreatedBy] = useState();
  const [groupId, setGroupId] = useState(selectedGroupId);
  const [userId, setUserId] = useState(0);
  const [startTime, setStartTime] = useState(new Date());
  const [dateTime, setDateTime] = useState(new Date());
  const [hasLag, setHasLag] = useState(false);
  const [isDependent, setIsDependent] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [members, setMembers] = useState([]);
  const [parentTask, setParentTask] = useState();
  const [startAt, setStartAt] = useState();
  const [lagDays, setLagDays] = useState();

  useEffect(() => {
    if (editTask) {
      setName(editTask.name);
      setDescription(editTask.description);
      setLabel(editTask.label);
      setPriorityId(editTask.priorityId);
      setTaskStatusId(editTask.taskStatusId);
      setDateTime(moment(editTask.dueDate));
      setStartTime(moment(editTask.startDate));
      setUserId(editTask.userId);
    }
  }, [user, editTask]);

  useEffect(() => {
    taskify
      .get(`/workspaces/workspaceById/${workspaceId}`)
      .then((workspace) => {
        let tasks = [];
        workspace.data.groups.forEach((group) => {
          group.tasks.forEach((task) => {
            tasks.push(task);
          });
        });
        setTasks(tasks);
      })
      .catch((err) => console.log(err));
  }, [workspaceId]);

  const onSaveClick = (e) => {
    e.preventDefault();
    const createdBy = user.id;
    const dueDate = dateTime;
    const startDate = startTime;
    const task = {
      name,
      description,
      label,
      startDate,
      dueDate,
      createdBy,
      priorityId,
      taskStatusId,
      groupId,
      userId,
      lagDays: lagDays ? lagDays : 0,
      parentId: isDependent ? parentTask.id : null,
    };

    if (!editTask) {
      taskify
        .post("/tasks", task)
        .then((res) => {
          if (userId === user.id) add(res.data);
          onSave(res.data);
        })
        .catch((err) => {
          closeTaskModal();
          alert("Something went wrong. Please try again");
        });
    } else {
      const newTask = {
        id: editTask.id,
        startDate: startTime,
        name,
        description,
        label,
        dueDate,
        priorityId,
        taskStatusId,
        userId,
        lagDays: lagDays ? lagDays : 0,
        parentId: isDependent ? parentTask.id : null,
      };

      taskify.put(`/tasks/${editTask.id}`, newTask).then((res) => {
        onEdit(res.data);
        closeTaskModal();
      });
    }
  };

  const onSetParentTask = (id) => {
    const parentTask = tasks.find((t) => t.id === id);
    setParentTask(parentTask);
  };

  const onStartAtChange = (id) => {
    if (id === 1) {
      setStartTime(parentTask.startDate);
    } else {
      setStartTime(parentTask.dueDate);
    }
    setStartAt(id);
  };

  const handleLag = (lag) => {
    setLagDays(lag);
    let newStartDate = moment(startTime).add(lag, "days");
    const dayOfWeek = moment(newStartDate).format("dddd");
    if (dayOfWeek === "Saturday") {
      newStartDate = moment(newStartDate).add(2, "days");
    } else if (dayOfWeek === "Sunday") {
      newStartDate = moment(newStartDate).add(1, "days");
    }
    setStartTime(newStartDate);
  };

  return (
    <div style={{ height: "85vh" }}>
      <h2> Task</h2>
      <Input
        fullWidth={true}
        variant="outlined"
        id="t-name"
        name="t-name"
        label="Task Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <Input
        fullWidth={true}
        variant="outlined"
        id="t-description"
        name="t-description"
        label="Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <Input
        fullWidth={true}
        variant="outlined"
        id="t-label"
        name="t-label"
        label="Label"
        value={label}
        onChange={(e) => setLabel(e.target.value)}
      />
      <DropDown
        fullWidth={true}
        name="t-priority"
        label="Priority"
        options={priority}
        selected={priorityId}
        onChange={(e) => setPriorityId(e.target.value)}
      />
      <DropDown
        fullWidth={true}
        name="t-assign"
        label="Assign To"
        options={teamMembers}
        selected={userId}
        onChange={(e) => setUserId(e.target.value)}
      />
      <Grid container direction="column">
        <div>
          <Checkbox
            onChange={() => {
              setStartAt();
              setStartTime(new Date());
              setIsDependent(!isDependent);
            }}
          />
          Is Dependent on any other task ?
        </div>
        {isDependent ? (
          <div>
            <DropDown
              fullWidth={true}
              name="dependent-task"
              label="Depends on"
              options={tasks}
              onChange={(e) => onSetParentTask(e.target.value)}
            />
            <DropDown
              fullWidth={true}
              name="startAt"
              label="Starts at"
              options={[
                { id: 1, name: "Start of Parent Task" },
                { id: 2, name: "End of Parent Task" },
              ]}
              onChange={(e) => onStartAtChange(e.target.value)}
            />
            <Checkbox
              onChange={() => {
                setHasLag(!hasLag);
                setLagDays();
              }}
            />
            Has Lag ?
            {hasLag ? (
              <Input
                type="number"
                fullWidth={true}
                variant="outlined"
                id="hasLag"
                name="hasLag"
                label="Number of Lag Days"
                value={lagDays}
                onChange={(e) => handleLag(e.target.value)}
              />
            ) : null}
          </div>
        ) : null}
      </Grid>
      {!startAt && !isDependent ? (
        <div>
          <p>Start Date</p>
          <DateTime
            value={startTime}
            onChange={(e) => setStartTime(e._d)}
            isValidDate={(current) =>
              current.isAfter(moment().subtract(1, "day"))
            }
          />
        </div>
      ) : null}

      <div>
        <p>Due Date</p>
        <DateTime
          value={dateTime}
          onChange={(e) => setDateTime(e._d)}
          isValidDate={(current) =>
            current.isAfter(moment().subtract(1, "day"))
          }
        />
      </div>
      <Grid container justify="flex-end">
        <Button
          onClick={closeTaskModal}
          style={{ marginTop: 5 }}
          color="primary"
          variant="contained"
        >
          Cancel
        </Button>
        <Button
          onClick={onSaveClick}
          style={{ marginTop: 5 }}
          color="primary"
          variant="contained"
        >
          Save
        </Button>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    priority: state.priority.priorities,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    add: (task) => {
      dispatch(addTask(task));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Task);
