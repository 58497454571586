import {
  ADD_WORK_SPACE,
  ERROR_ADD_WORKSPACE,
  GET_WORK_SPACE_BY_USER_ID,
  GET_WORK_SPACE_BY_USER_ID_FAILED,
  TOGGLE_ISACTIVE,
} from "../actionTypes/workspaceActionTypes";

const initialState = {
  workspaces: [],
  error: "",
};

export const workspaceReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_WORK_SPACE_BY_USER_ID:
      return {
        ...state,
        workspaces: action.payload.workspaces,
      };

    case GET_WORK_SPACE_BY_USER_ID_FAILED:
      return {
        ...state,
        error: action.payload.error,
      };

    case ADD_WORK_SPACE:
      return {
        ...state,
        workspaces: [...state.workspaces, action.payload.workspace],
      };
    case ERROR_ADD_WORKSPACE:
      return {
        ...state,
        workspaces: state.workspaces.slice(0, state.workspaces.length),
        error: action.payload.error,
      };
    case TOGGLE_ISACTIVE:
      const index = state.workspaces.findIndex(
        (w) => w.id === action.payload.id
      );
      const updatedWorkspaces = [...state.workspaces];
      updatedWorkspaces[index].isActive = !updatedWorkspaces[index].isActive;

      return {
        ...state,
        workspaces: updatedWorkspaces,
      };

    default: {
      return state;
    }
  }
};
