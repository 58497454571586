import { GET_TEAM_MEMBERS_BY_COMPANY_ID } from "../actionTypes/memberActionTypes";
import { srvTeamMemberByCompanyId } from "../../services/TeamMemberServices";
export const getTeamMembersByCompanyId = (teamMembers) => {
  return {
    type: GET_TEAM_MEMBERS_BY_COMPANY_ID,
    payload: { teamMembers },
  };
};

export const asyncGetTeamMembersByCompanyId = (id) => {
  return async (dispatch) => {
    try {
      const TeamsByCompany = await srvTeamMemberByCompanyId(id);
      dispatch(getTeamMembersByCompanyId(TeamsByCompany.data));
    } catch (err) {
      console.log(err);
    }

    // taskify
    //   .get(`teamMembers/company/${id}`)
    //   .then((res) => {
    //     console.log(res.data, "DATA");
    //     dispatch(getTeamMembersByCompanyId(res.data));
    //     return Promise.resolve();
    //   })
    //   .catch((err) => console.log(err));
  };
};
