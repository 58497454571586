import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import { connect } from "react-redux";
import { asyncAddClient } from "../../redux/actionCreators/clientActionCreators";
import Button from "../button/Button";
import Input from "../input/Input";

const ClientForm = ({ closeModal, user, addClient }) => {
  const [name, setName] = useState();
  const [address, setAddress] = useState();
  const [address2, setAddress2] = useState();
  const [description, setDescription] = useState();

  const onSaveClick = () => {
    const createdBy = user.id;
    const companyId = user.companyId;

    const client = {
      name,
      address,
      address2,
      description,
      createdBy,
      companyId,
    };
    addClient(client);
    closeModal();
  };

  return (
    <div>
      <h2>Add Client</h2>
      <Input
        fullWidth={true}
        variant="outlined"
        id="c-name"
        name="c-name"
        label="Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <Input
        fullWidth={true}
        variant="outlined"
        id="c-address"
        name="c-address"
        label="Address"
        value={address}
        onChange={(e) => setAddress(e.target.value)}
      />
      <Input
        fullWidth={true}
        variant="outlined"
        id="c-address2"
        name="c-address2"
        label="Address 2"
        value={address2}
        onChange={(e) => setAddress2(e.target.value)}
      />
      <Input
        fullWidth={true}
        variant="outlined"
        id="c-description"
        name="c-description"
        label="Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <Grid container justify="flex-end">
        <Button onClick={closeModal} color="primary" variant="contained">
          Close
        </Button>
        <Button onClick={onSaveClick} color="primary" variant="contained">
          Save
        </Button>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addClient: (client) => {
      dispatch(asyncAddClient(client));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientForm);
