import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import { Link } from "react-router-dom";
import UserTaskModal from "../userTaskModal/userTaskModal";
import taskify from "../../api/taskify";
import { FaDochub, FaRegComments, FaRegEdit } from "react-icons/fa";
import Priority from "../priority/PriorityComponent";
import { Collapse } from "@material-ui/core";
import ModalComponent from "../modal/modal";
import AssignTask from "../assignTask/AssignTask";
import Can from "../../services/CanAccess/canAccess";
import Task from "../../components/task/task";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
    padding: theme.spacing(0, 3),
  },
  paper: {
    maxWidth: "150vh",
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
  },
}));

const UserTask = ({
  task,
  workspaceId,
  updateTaskStatus,
  showStatus,
  team,
  onAssignTask,
  onEdit,
  teamMembers,
}) => {
  const classes = useStyles();

  const [taskModal, setTaskModal] = useState(false);
  const [assignTaskModal, setAssignModal] = useState(false);
  const [status, setTaskStatus] = useState([]);
  const [editTaskId, setEditTaskID] = useState();

  useEffect(() => {
    taskify
      .get("/taskStatus")
      .then((res) => {
        setTaskStatus(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const onAssignTaskModalClose = () => {
    setAssignModal(false);
  };

  const diplayDocumentCount = () => {
    let count = 0;

    if (task.comments) {
      task.comments.map((com) => {
        if (com.documents && com.documents.length > 0) {
          count += 1;
        }
      });
    } else {
      return 0;
    }
    return count;
  };

  const displayLatestComment = (task) => {
    const comment = task.comments[task.comments.length - 1];
    return comment ? (
      <p>
        {`${comment.user.fName} says: 
        ${comment.comment}`}
      </p>
    ) : null;
  };

  return (
    <div>
      <Paper className={classes.paper}>
        <Grid container wrap="nowrap" spacing={2}>
          <Grid item xs>
            <Typography>
              <Link onClick={() => setTaskModal(!taskModal)}>{task.name}</Link>
            </Typography>
          </Grid>
          {task.user ? (
            <Grid item xs>
              <Typography> {task.user.fName} </Typography>
            </Grid>
          ) : null}
          {task.group ? (
            <Grid item xs>
              <Typography>{task.group.workspace.name}</Typography>
            </Grid>
          ) : null}
          <Grid item xs>
            <Priority name={task.priority.name} />
          </Grid>

          {task.startDate ? (
            <Grid item xs>
              <Typography>
                {status[task.taskStatusId]
                  ? status[task.taskStatusId - 1].name
                  : null}
              </Typography>
            </Grid>
          ) : null}
          {task.startDate ? (
            <Grid item xs>
              <Typography>
                {moment(task.startDate).format("MM/DD/YYYY")}
              </Typography>
            </Grid>
          ) : null}
          {task.dueDate ? (
            <Grid item xs>
              <Typography>
                {moment(task.dueDate).format("MM/DD/YYYY")}
              </Typography>
            </Grid>
          ) : (
            <p>Edit task to Assign </p>
          )}

          <Grid item xs>
            <Typography align="center">
              <FaRegComments size={25} color="purple" />
              <sup>{task.comments.length}</sup>
            </Typography>
          </Grid>

          <Grid item xs>
            <Typography>
              <FaDochub size={25} />
              <sup>{diplayDocumentCount()}</sup>
            </Typography>
          </Grid>

          <Can canActivate={["PCA", "Admin"]}>
            <Grid item xs>
              <Typography onClick={() => setEditTaskID(task.id)}>
                <FaRegEdit size={25} color="green" />
              </Typography>
            </Grid>
          </Can>
        </Grid>
        {displayLatestComment(task)}
      </Paper>

      <Collapse in={taskModal} timeout="auto" unmountOnExit>
        <Paper style={{ width: "84%", marginLeft: "8%" }}>
          <UserTaskModal
            task={task}
            updateStatus={updateTaskStatus}
            status={status}
            closeModal={() => setTaskModal(false)}
            showStatus={showStatus}
          />
        </Paper>
      </Collapse>

      <ModalComponent open={assignTaskModal}>
        <AssignTask
          taskId={task.id}
          members={team}
          onAssignTask={onAssignTask}
          toggleModal={onAssignTaskModalClose}
        />
      </ModalComponent>
      <ModalComponent open={editTaskId}>
        <Task
          editTask={task}
          onEdit={onEdit}
          workspaceId={workspaceId}
          teamMembers={teamMembers}
          closeTaskModal={() => setEditTaskID()}
        />
      </ModalComponent>
    </div>
  );
};

export default UserTask;
