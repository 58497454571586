import React, { memo } from "react";
import { TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
//onChange for handling input change
//value for displaying current value

const useStyles = makeStyles((theme) => ({
  bg: {
    background: "pink",
  },
}));

const Input = ({
  error,
  type,
  fullWidth,
  style,
  multiline,
  endAdornment,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <div>
      <div>
        <TextField
          type={type || "value"}
          autoComplete="off"
          margin="normal"
          fullWidth={false || fullWidth}
          multiline={false || multiline}
          rows="5"
          {...rest}
        />
      </div>
      {error && <Typography className={classes.bg}>{error}</Typography>}
    </div>
  );
};

export default memo(Input);
