import React from 'react'
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";

export default function dateTime(props) {
    return (

            <Datetime {...props}/>
    )
}
