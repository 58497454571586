import taskify from "../../api/taskify";
import { srvTeamsByCompanyId } from "../../services/TeamServices";
import { srvAddTeams } from "../../services/TeamServices";
import {
  ADD_TEAM,
  ADD_TEAM_FAILED,
  GET_TEAMS_BY_COMPANY,
} from "../actionTypes/teamActionType";

export const getTeamsByCompany = (teams) => {
  return {
    type: GET_TEAMS_BY_COMPANY,
    payload: { teams },
  };
};

export const asyncGetTeamsByCompany = (id) => {
  return async (dispatch) => {
    try {
      const TeamsByCompany = await srvTeamsByCompanyId(id);
      dispatch(getTeamsByCompany(TeamsByCompany.data));
    } catch (err) {
      console.log(err);
    }
  };
};

export const addTeam = (team) => {
  return {
    type: ADD_TEAM,
    payload: { team },
  };
};

export const asyncAddTeam = (team) => {
  return (dispatch) => {
    taskify
      .post("/teams", team)
      .then((res) => {
        dispatch(addTeam(res.data));
      })
      .catch((err) => dispatch(addTeamFailed(err.response.data)));
  };
};

export const addTeamFailed = (error) => {
  return {
    type: ADD_TEAM_FAILED,
    payload: { error },
  };
};
