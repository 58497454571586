import taskify from "../api/taskify";

export const srvClientsByUserId= (id)=>{
 return taskify.get(`/clients/${id}`)  
}

export const srvAddClients = (client)=>{
 return taskify
      .post("/clients", client )
}


export const srvAddClientResp = (clientRepresentative) =>{
      return taskify.post("/clientReps", clientRepresentative)
}