import { Grid, makeStyles } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Can from "../../services/CanAccess/canAccess";

import {
  FaRegCalendarTimes,
  FaRegCalendarCheck,
  FaRegCalendarAlt,
  FaWrench,
} from "react-icons/fa";
import CardWithIcon from "../../components/cardWithIcon/cardWithIcon/CardWithIcon";
import Layout2 from "../../LayoutsForDashboard/Layout2";
import TaskGridComponent from "../../components/taskGridComponent/taskGridComponent";
import { connect } from "react-redux";
import WorkspaceDetailsList from "../../components/workspaceDetailsList/WorkspaceDetailsList";
import UserTaskList from "../../components/userTaskList/UserTaskList";
import taskify from "../../api/taskify";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  container: {
    borderRadius: theme.spacing(2),
    border: "1px solid lightgrey",
    padding: theme.spacing(0, 0, 0, 0),
    margin: theme.spacing(0, 0, 10, 0),
  },
  heading: {
    padding: theme.spacing(2, 3, 2, 3),
    borderTopRightRadius: theme.spacing(2),
    borderTopLeftRadius: theme.spacing(2),
    marginBottom: theme.spacing(3),
    color: "white",
  },
}));

const Dashboard = ({ user, clients, teams, workspaces, templates }) => {
  const classes = useStyles();

  const [tasks, setTasks] = useState([]);
  const [count, setCount] = useState({
    assigned: 0,
    inProgress: 0,
    done: 0,
  });

  useEffect(() => {
    taskify
      .get(`/tasks/getTasksByUserId/${user.id}`)
      .then((res) => {
        console.log(res.data, "TasksByID");
        setTasks(res.data);
      })
      .catch((err) => console.log(err));
  }, [user]);

  useEffect(() => {
    let assigned = 0;
    let inProgress = 0;
    let done = 0;
    tasks.map((task) => {
      if (task.taskStatusId === 1) assigned++;
      else if (task.taskStatusId === 2) inProgress++;
      else done++;
    });

    setCount((prevState) => {
      return {
        ...prevState,
        assigned: assigned,
        inProgress: inProgress,
        done: done,
      };
    });
  }, [tasks]);

  const updateTaskStatus = (task, updatedId) => {
    const newTasks = [...tasks];
    const index = newTasks.findIndex((t) => t.id === task.id);
    newTasks[index].taskStatusId = updatedId;
    setTasks(newTasks);
    taskify
      .put(`/tasks/${task.id}`, { taskStatusId: updatedId })
      .then((res) => {})
      .catch((err) => {});
  };

  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Dashboard</Link>
          </li>
        </ol>
      </nav>
      <div>
        {
          <Layout2
            R2C1={
              <CardWithIcon
                iconColor={"success"}
                Icon={FaRegCalendarAlt}
                title={user.role === "User" ? "Assigned" : "Workspace"}
                caption={
                  user.role === "User" ? count.assigned : workspaces.length
                }
              />
            }
            R2C2={
              <CardWithIcon
                iconColor={"rose"}
                Icon={FaWrench}
                title={user.role === "User" ? "In Progress" : "Templates"}
                caption={
                  user.role === "User" ? count.inProgress : templates.length
                }
              />
            }
            R2C3={
              <CardWithIcon
                Icon={FaRegCalendarCheck}
                title={user.role === "User" ? "Done" : "Teams"}
                caption={user.role === "User" ? count.done : teams.length}
              />
            }
            R2C4={
              <CardWithIcon
                iconColor={"info"}
                Icon={FaRegCalendarTimes}
                title={user.role === "User" ? "----" : "Clients"}
                caption={clients.length}
              />
            }
          />
        }
      </div>
      <Can canActivate={["PCA", "Admin"]}>
        <WorkspaceDetailsList />
        <Grid
          container
          flex-direction="row"
          alignItems="center"
          style={{ marginTop: 20, marginBottom: 25 }}
        >
          {/* {user ? <TaskGridComponent /> : null} */}
        </Grid>
      </Can>

      <Can canActivate={["User"]}>
        {tasks ? (
          <UserTaskList tasks={tasks} updateTaskStatus={updateTaskStatus} />
        ) : null}
      </Can>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    clients: state.client.clients,
    teams: state.team.teams,
    workspaces: state.workspace.workspaces,
    templates: state.template.templates,
  };
};

export default connect(mapStateToProps)(Dashboard);
