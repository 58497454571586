import {
  srvAddWorkspaces,
  srvUpdateWorkspacesById,
  srvWorkspacesByUserId,
} from "../../services/WorkspaceServices";

import {
  ADD_WORK_SPACE,
  GET_WORK_SPACE_BY_USER_ID,
  GET_WORK_SPACE_BY_USER_ID_FAILED,
  ERROR_ADD_WORKSPACE,
  TOGGLE_ISACTIVE,
} from "../actionTypes/workspaceActionTypes";

export const addWorkSpace = (workspace) => {
  return {
    type: ADD_WORK_SPACE,
    payload: { workspace },
  };
};

export const asyncAddWorkSpace = (workspace) => {
  return async (dispatch) => {
    try {
      const res = await srvAddWorkspaces(workspace);
      dispatch(addWorkSpace(res.data));
    } catch (err) {
      dispatch(addWorkSpaceFailed(err.message));
    }
  };
};

export const addWorkSpaceFailed = (error) => {
  return {
    type: ERROR_ADD_WORKSPACE,
    payload: { error },
  };
};

export const getWorkSpaceByUserId = (workspaces) => {
  return {
    type: GET_WORK_SPACE_BY_USER_ID,
    payload: { workspaces },
  };
};

export const getWorkSpaceByUserIdFailed = (error) => {
  return {
    type: GET_WORK_SPACE_BY_USER_ID_FAILED,
    payload: { error },
  };
};

export const asyncGetWorkSpaceByUserId = (id) => {
  return async (dispatch) => {
    try {
      const WorkspaceByUserId = await srvWorkspacesByUserId(id);
      dispatch(getWorkSpaceByUserId(WorkspaceByUserId.data));
    } catch (err) {
      console.log(err);
    }
  };
};

export const toggleIsActive = (id) => {
  return {
    type: TOGGLE_ISACTIVE,
    payload: { id },
  };
};

export const asyncToggleIsActive = (item) => {
  return async (dispatch) => {
    try {
      const updateWorkspace = await srvUpdateWorkspacesById(item.id, {
        isActive: !item.isActive,
      });
      dispatch(toggleIsActive(item.id));
    } catch (err) {
      console.log(err);
    }
  };
};
