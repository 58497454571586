import taskify from "../api/taskify";
export const srvTeamsByCompanyId = (id) => {
  return taskify.get(`/teams/getTeamsByCompany/${id}`);
};

export const srvAddTeams = (team) => {
  return taskify.post("/teams", team);
};

export const srvTeamsDetails = (teamId) => {
  return taskify.get(`/teamMembers/getTeamMembers/${teamId}`);
};

export const srvAddTeamsMember = (teamMember) => {
  return taskify.post("/teamMembers/", teamMember);
};
