
import React from "react"
import TemplateListing from "../../components/templateListing/templateListing"

const templateListing = ({history})=>{

    return(
        <TemplateListing history={history}></TemplateListing>

    )
}
export default templateListing;