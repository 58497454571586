import React, { useEffect } from "react";
import jwt from "jsonwebtoken";

const InvitationLanding = ({ history, match }) => {
  useEffect(() => {
    const token = match.params.token;
    const decoded = jwt.verify(token, "taskify");
    localStorage.setItem("token", token);
    localStorage.setItem("decoded", JSON.stringify(decoded));
    history.push("/register");
  }, [match.params.token, history]);

  return <div></div>;
};

export default InvitationLanding;
