import { Grid } from "@material-ui/core";
import React from "react";

const Priority = ({ name }) => {
  const setColor = () => {
    if (name === "Low") return "yellow";
    else if (name === "Medium") return "green";
    else return "red";
  };

  return (
    <Grid>
      <p
        style={{
          width: 75,
          border: "1px solid",
          borderColor: setColor(),
          backgroundColor: setColor(),
          color: "white",
          paddingLeft: 8,
          paddingRight: 8,
          borderRadius: 100,
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        {name}
      </p>
    </Grid>
  );
};

export default Priority;
