import { Grid, Paper } from "@material-ui/core";
import React from "react";

const User = ({ user, index }) => {
  return (
    <div>
      <Paper style={{ paddingTop: 8, marginTop: 5 }}>
        <Grid container direction="row" justify="space-evenly" align="center">
          <p style={{ paddingLeft: 14 }}>{`${index + 1}`}</p>
          <Grid item xs>
            <p>{`${user.fName} ${user.lName}`}</p>
          </Grid>
          <Grid item xs>
            <p>{`${user.email}`}</p>
          </Grid>

          <Grid item xs>
            <p>{`${user.role.name}`}</p>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default User;
