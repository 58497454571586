import React, { useCallback, useEffect, useState } from "react";
import taskify from "../../api/taskify";
import ButtonComponent from "../../components/button/Button";
import Group from "../../components/group/group";
import ModalComponent from "../../components/modal/modal";
import Task from "../../components/task/task";
import { Grid, Paper, makeStyles, Collapse } from "@material-ui/core";
import Dropdown from "../../components/dropdown/Dropdown";
import { Link } from "react-router-dom";
import AssignTask from "../../components/assignTask/AssignTask";
import { connect } from "react-redux";
import moment from "moment";
import Can from "../../services/CanAccess/canAccess";
import Priority from "../../components/priority/PriorityComponent";
import userTaskModal from "../../components/userTaskModal/userTaskModal";
import UserTask from "../../components/userTask/userTask";
import AssignTeam from "../../components/AssignTeam/AssignTeam";
import TimeLine from "react-gantt-timeline";
import "./workspace.css";
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(5),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  overflow: {
    wordBreak: "break-word",
  },
}));

const Workspace = ({ match, user }) => {
  const classes = useStyles();

  const [data, setData] = useState({
    teamId: 0,
    groups: [],
    toggleGroupModal: false,
    toggleTaskModal: false,
    groupId: 0,
    workspaceName: "",
    isActive: false,
  });

  const [status, setStatus] = useState([]);
  const [team, setTeam] = useState([]);
  const [selectedTask, setSelectedTask] = useState();
  const [workspaceId, setWorkspaceId] = useState(match.params.id);
  const [taskId, setTaskId] = useState(0);
  const [groupIndex, setGroupIndex] = useState(0);
  const [toggleAssignTask, setToggleAssignTask] = useState(false);
  const [toggleAssignTeam, setToggleAssignTeam] = useState(false);
  const [gantt, setGantt] = useState();
  const [links, setLinks] = useState();

  const onAddGroup = () => {
    setData((prevState) => {
      return { ...prevState, toggleGroupModal: true };
    });
  };

  const addGroup = (group) => {
    setData((prevState) => {
      return {
        ...prevState,
        groups: [...prevState.groups, group],
        toggleGroupModal: false,
      };
    });
  };

  const closeGroupModal = () => {
    setData((prevState) => {
      return {
        ...prevState,
        toggleGroupModal: false,
      };
    });
  };

  const closeTaskModal = () => {
    setData((prevState) => {
      return { ...prevState, toggleTaskModal: false };
    });
  };

  const addTask = (task) => {
    console.log(task);
    const index = data.groups.findIndex((group) => {
      return group.id === data.groupId;
    });

    let priority = "";
    if (task.priorityId === 1) priority = "Low";
    else if (task.priorityId === 2) priority = "Medium";
    else priority = "High";

    const assignTo = team.find((member) => member.id === task.userId);
    task["user"] = { fName: assignTo.name };
    task["priority"] = { id: task.priorityId, name: priority };
    task["taskStatus"] = { name: "Assigned" };
    task["documents"] = [];
    task["comments"] = [];

    const groups = data.groups;
    if (!groups[index].tasks) {
      groups[index]["tasks"] = [task];
      setData((prevState) => {
        return { ...prevState, groups: groups, toggleTaskModal: false };
      });
      return;
    }
    groups[index].tasks.push(task);
    setData((prevState) => {
      return { ...prevState, groups: groups, toggleTaskModal: false };
    });
  };

  const _assignTask = (taskId, dueDate, name) => {
    const { groups } = { ...data };
    const newGroups = groups.map((group) => {
      group.tasks.map((task) => {
        if (task.id === taskId) {
          task.dueDate = dueDate;
          task["user"] = { fName: name };
        }
      });
    });

    // setData((prevState) => {
    //   return { ...prevState, groups: newGroups };
    // });
  };

  const onAssignTaskModalClose = () => {
    setToggleAssignTask(false);
  };
  const edit = (newtask) => {
    data.groups.map((group, index) => {
      group.tasks.map((task, taskIndex) => {
        if (task.id === newtask.id) {
          const newGroups = [...data.groups];
          newGroups[index].tasks[taskIndex] = newtask;
          setData((prevState) => {
            return { ...prevState, groups: newGroups };
          });
        }
      });
    });
  };

  const onStatusChange = (e, groupIndex, taskIndex) => {
    const newGroups = [...data.groups];
    const task = newGroups[groupIndex].tasks[taskIndex];
    task.taskStatus.id = e.target.value;

    setData((prevState) => {
      return { ...prevState, groups: newGroups };
    });

    const updatedId = task.taskStatus.id;
    taskify
      .put(`/tasks/${task.id}`, { taskStatusId: updatedId })
      .then()
      .catch((err) => {});
  };

  const onAssignTeamModalClose = () => {
    setToggleAssignTeam(false);
  };

  const generateColor = () => {
    const colors = ["red", "yellow", "blue", "green", "black", "gray", "brown"];
    return colors[Math.floor(Math.random() * 6)];
  };

  const createGanttData = useCallback(() => {
    let ganttData = [];
    let links = [];
    data.groups.forEach((g) => {
      if (g.tasks && g.tasks.length) {
        g.tasks.forEach((t) => {
          ganttData.push({
            id: t.id,
            start: t.startDate,
            end: t.dueDate,
            name: t.name,
            color: generateColor(),
          });
          if (t.parentId !== null) {
            links.push({ id: t.id, start: t.parentId, end: t.id });
          }
        });
      }
    });
    console.log(ganttData, "Gantt");
    setGantt(ganttData);
    setLinks(links);
  }, [data]);

  useEffect(() => {
    taskify
      .get(`/workspaces/workspaceById/${workspaceId}`)
      .then((res) => {
        setData((prevState) => {
          return {
            ...prevState,
            teamId: res.data.teamId,
            groups: res.data.groups,
            workspaceName: res.data.name,
            isActive: res.data.isActive,
          };
        });
      })
      .catch((err) => console.log(err));
  }, [workspaceId]);

  useEffect(() => {
    let members = [];
    taskify
      .get(`/teamMembers/getTeamMembers/${data.teamId}`)
      .then((res) => {
        res.data.forEach((member) => {
          members.push({ id: member.userId, name: member.user.name });
        });

        setTeam(members);
      })
      .catch((err) => console.log(err));
  }, [data.teamId]);

  useEffect(() => {
    taskify
      .get("/taskStatus")
      .then((res) => {
        setStatus(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    createGanttData();
  }, [createGanttData]);

  const dataG = [
    {
      id: 17,
      start: "2020-12-10 15:18:12",
      end: "2020-12-17 15:18:12",
      name: "Demo Task 1",
    },
    {
      id: 18,
      start: "2020-12-18 15:18:54",
      end: "2020-12-21 15:26:17",
      name: "Demo Task 2",
      color: "orange",
    },
    {
      id: 19,
      start: "2020-12-22 17:24:44",
      end: "2020-12-24 17:24:44",
      name: "Demo Task 2",
      color: "orange",
    },
  ];

  // const links = [
  //   { id: 1, start: 17, end: 18 },
  //   { id: 2, start: 18, end: 19 },
  // ];

  const ganttOptions = {
    header: {
      //Targert the time header containing the information month/day of the week, day and time.
      top: {
        //Tartget the month elements
        style: { backgroundColor: "#0091ea", fontSize: 12 }, //The style applied to the month elements
      },
      middle: {
        //Tartget elements displaying the day of week info
        style: { backgroundColor: "#0091ea", fontSize: 12 }, //The style applied to the day of week elements
        selectedStyle: { backgroundColor: "#b13525" }, //The style applied to the day of week elements when is selected
      },
      bottom: {
        //Tartget elements displaying the day number or time
        style: { background: "#0091ea", fontSize: 12 }, //the style tp be applied
        selectedStyle: { backgroundColor: "#b13525", fontWeight: "bold" }, //the style tp be applied  when selected
      },
    },
    taskList: {
      //the right side task list
      title: {
        //The title od the task list
        label: "Tasks", //The caption to display as title
        style: {
          backgroundColor: "#0091ea",
          borderBottom: "solid 1px silver",
          color: "white",
          textAlign: "center",
          fontSize: 16,
        }, //The style to be applied to the title
      },
      task: {
        // The items inside the list diplaying the task
        style: { backgroundColor: "#fbf9f9" }, // the style to be applied
      },
      verticalSeparator: {
        //the vertical seperator use to resize he width of the task list
        style: { backgroundColor: "#0091ea" }, //the style
        grip: {
          //the four square grip inside the vertical separator
          style: { backgroundColor: "white" }, //the style to be applied
        },
      },
    },
    dataViewPort: {
      //The are where we display the task
      rows: {
        //the row constainting a task
        style: {
          backgroundColor: "#fbf9f9",
          borderBottom: "solid 0.5px #cfcfcd",
        },
      },
      task: {
        //the task itself
        showLabel: false, //If the task display the a lable
        style: {
          position: "absolute",
          borderRadius: 14,
          color: "white",
          textAlign: "center",
          backgroundColor: "grey",
        },
        selectedStyle: {}, //the style tp be applied  when selected
      },
    },
    links: {
      //The link between two task
      color: "black",
      selectedColor: "#ff00fa",
    },
  };

  return (
    <div>
      <Grid container justify="space-between">
        <h2>{data.workspaceName}</h2>

        <TimeLine data={gantt} links={links} config={ganttOptions} />

        {team.length > 0 ? null : (
          <ButtonComponent
            color="primary"
            variant="contained"
            onClick={() => setToggleAssignTeam(!toggleAssignTeam)}
          >
            Assign Team To Workspace
          </ButtonComponent>
        )}
      </Grid>
      <Can canActivate={["PCA", "Admin"]}>
        {data.isActive ? (
          <ButtonComponent
            onClick={onAddGroup}
            color="primary"
            variant="contained"
            style={{ marginTop: 10 }}
          >
            Add Group
          </ButtonComponent>
        ) : null}
      </Can>

      <div>
        {data.groups.length > 0
          ? data.groups.map((item, groupIndex) => {
              return (
                <Grid
                  container
                  direction="column"
                  justify="space-around"
                  key={groupIndex}
                >
                  <div style={{ marginTop: 20 }}>
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      key={item.id}
                    >
                      <h2>{item.name}</h2>

                      <Can canActivate={["PCA", "Admin"]}>
                        {data.isActive ? (
                          <ButtonComponent
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              setData((prevState) => {
                                return {
                                  ...prevState,
                                  toggleTaskModal: true,
                                  groupId: item.id,
                                };
                              });
                            }}
                          >
                            Create Task
                          </ButtonComponent>
                        ) : null}
                      </Can>
                    </Grid>

                    {item.tasks && item.tasks.length ? (
                      item.tasks.map((task, index) => {
                        return (
                          <UserTask
                            task={task}
                            key={task.id}
                            team={team}
                            workspaceId={workspaceId}
                            onEdit={edit}
                            teamMembers={team}
                            onAssignTask={_assignTask}
                          />
                        );
                      })
                    ) : (
                      <p> No Task in this Group </p>
                    )}
                  </div>
                </Grid>
              );
            })
          : null}
      </div>
      <ModalComponent open={data.toggleGroupModal}>
        <Group
          onSave={addGroup}
          teamMembers={team}
          closeGroupModal={closeGroupModal}
          workspaceId={workspaceId}
        />
      </ModalComponent>

      <ModalComponent open={data.toggleTaskModal}>
        <Task
          onSave={addTask}
          workspaceId={workspaceId}
          teamMembers={team}
          closeTaskModal={closeTaskModal}
          selectedGroupId={data.groupId}
        />
      </ModalComponent>

      <ModalComponent open={toggleAssignTask}>
        {data.groups ? (
          <AssignTask
            taskId={taskId}
            members={team}
            onAssignTask={_assignTask}
            toggleModal={onAssignTaskModalClose}
          />
        ) : null}
      </ModalComponent>
      <ModalComponent open={toggleAssignTeam}>
        <AssignTeam
          workspaceId={workspaceId}
          onClose={onAssignTeamModalClose}
        />
      </ModalComponent>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(Workspace);
