import taskify from "../../api/taskify";
import { srvGetPriority } from "../../services/Priority";
import { GET_PRIORITY } from "../actionTypes/priorityActionTypes";

export const getPriorities = (priorities) => {
  return {
    type: GET_PRIORITY,
    payload: { priorities },
  };
};

export const asyncGetPriorities = () => {
  return async (dispatch) => {
    const Priority = await srvGetPriority();
    dispatch(getPriorities(Priority.data));
  };
};
