import taskify from "../../api/taskify";
import jwt from "jsonwebtoken";
import { ERROR_LOGIN, LOGIN } from "../actionTypes/authActionTypes";
import { asyncGetWorkSpaceByUserId } from "./workspaceActionCreators";
import { asyncGetPriorities } from "./priorityActionCreators";
import { asyncGetTeamMembersByCompanyId } from "./memberActionCreator";
import { asyncGetTeamsByCompany } from "./teamActionCreators";
import { asyncGetClientsByUserId } from "./clientActionCreators";
import { asyncGetTasksByUserId } from "./taskActionCreators";

export const Login = (token, user) => {
  return {
    type: LOGIN,
    payload: { token, user },
  };
};

export const LoginFailed = (error) => {
  return {
    type: ERROR_LOGIN,
    payload: { error },
  };
};

export const asyncLogin = (user) => {
  return (dispatch) => {
    taskify
      .post("/auth", user)
      .then(async (res) => {
        const { token } = res.data;
        const decoded = jwt.verify(token, "taskify");
        dispatch(Login(token, decoded));
        await dispatch(asyncGetTasksByUserId(decoded.id));
        await dispatch(asyncGetWorkSpaceByUserId(decoded.id));
        await dispatch(asyncGetClientsByUserId(decoded.id));
        await dispatch(asyncGetTeamMembersByCompanyId(decoded.companyId));
        await dispatch(asyncGetTeamsByCompany(decoded.companyId));
        await dispatch(asyncGetPriorities());
      })
      .catch((err) => {
        console.log(err, "Auth Error");
        dispatch(LoginFailed(err.response.data));
      });
  };
};
