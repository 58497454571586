import React, { useState } from "react";
import { connect } from "react-redux";
import Button from "../../components/button/Button";
import ClientForm from "../../components/clientForm/clientForm";
import ClientRepForm from "../../components/clientRepForm/clientRepForm";

import ModalComponent from "../../components/modal/modal";

const Client = ({ clients }) => {
  const [toggleClientModal, setToggleClientModal] = useState();
  const [toggleClientRepModal, setToggleClientRepModal] = useState();

  const [clientId, setClientId] = useState();

  const onClientModalOpen = () => {
    setToggleClientModal(true);
  };

  const onClientModalClose = () => {
    setToggleClientModal(false);
  };

  const onClientRepModalClose = () => {
    setToggleClientRepModal(false);
  };

  const displayClients = () => {
    return (
      <table className="table">
        <thead>
          <tr>
            <td>Name</td>
            <td>Description</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {clients.map((client, index) => {
            return (
              <tr key={client.id}>
                <td>{client.name}</td>
                <td>{client.description}</td>
                <td>
                  <Button
                    onClick={() => {
                      setClientId(client.id);
                      setToggleClientRepModal(true);
                    }}
                    color="primary"
                    variant="contained"
                  >
                    Add Representative
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  return (
    <React.Fragment>
      <Button onClick={onClientModalOpen} color="primary" variant="contained">
        Add Client
      </Button>
      {clients ? displayClients() : <p>You do not have any Clients yet</p>}
      {toggleClientModal ? (
        <ModalComponent open={toggleClientModal}>
          <ClientForm closeModal={onClientModalClose} />
        </ModalComponent>
      ) : null}
      {toggleClientRepModal ? (
        <ModalComponent open={toggleClientRepModal}>
          <ClientRepForm
            clientId={clientId}
            closeModal={onClientRepModalClose}
          />
        </ModalComponent>
      ) : null}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    clients: state.client.clients,
  };
};

export default connect(mapStateToProps)(Client);