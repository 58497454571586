import { connect } from "react-redux";

const Can = ({ children, user, canActivate }) => {
  return canActivate.includes(user.role) ? children : null;
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(Can);
