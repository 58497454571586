import React,{memo} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {Typography} from "@material-ui/core"
import styles from "../../../assets/jss/material-dashboard-react/views/dashboardStyle.js";

import Danger from '../../typography/Danger'

import Card from "../cardWithIcon/Card.js";
import CardHeader from "../cardWithIcon/CardHeader.js";
import CardIcon from "../cardWithIcon/CardIcon.js";
import CardBody from "../cardWithIcon/CardBody.js";
import CardFooter from "../cardWithIcon/CardFooter.js";


const useStyles = makeStyles(styles);

const CardWithIcon=({Icon,title,iconColor,caption,FooterIcon,FooterText,footerIconColor})=>{
  const classes = useStyles();

  return(
      <Card>
        <CardHeader color="warning" stats icon>
          <CardIcon color={iconColor || "warning"}>
            {/* <Icon>content_copy</Icon> */}
            <Icon/>
          </CardIcon>
          <p className={classes.cardCategory}>{title}</p>
          <h3 className={classes.cardTitle}>
            {caption}
          </h3>
        </CardHeader>
        {/* <CardFooter stats>
          <div className={classes.stats}>
            <Danger color={footerIconColor}>
              <FooterIcon />
            </Danger>
            <Typography variant="caption">
            {FooterText}   
            </Typography>
          </div>
        </CardFooter> */}
      </Card>
  )
}

export default memo(CardWithIcon)