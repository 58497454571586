import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import taskify from "../../api/taskify";
import ButtonComponent from "../button/Button";
import Dropdown from "../dropdown/Dropdown";

const AssignTeam = ({ workspaceId, user, onClose }) => {
  const [teams, setTeams] = useState([]);
  const [teamId, setTeamId] = useState();

  useEffect(() => {
    taskify
      .get(`teams/getTeamsByCompany/${user.companyId}`)
      .then((res) => setTeams(res.data))
      .catch((err) => console.log(err));
  });

  const onSaveClick = () => {
    taskify.put(`workspaces/${workspaceId}`, { teamId: teamId }).then((res) => {
      onClose();
    });
  };

  return (
    <div>
      <Dropdown
        fullWidth={true}
        name="t-assign"
        label="Assign Team"
        options={teams}
        onChange={(e) => setTeamId(e.target.value)}
      />
      <Grid container justify="flex-end">
        <ButtonComponent
          color="primary"
          variant="contained"
          onClick={onSaveClick}
        >
          Save
        </ButtonComponent>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(AssignTeam);
