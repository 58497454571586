import taskify from "../api/taskify";

export const srvWorkspacesByUserId = (id) => {
  return taskify.get(`/workspaces/workspaceByUserId/${id}`);
};

export const srvAddWorkspaces = (workspace) => {
  return taskify.post("/workspaces", workspace);
};

export const srvUpdateWorkspacesById = (id, workspace) => {
  return taskify.put(`/workspaces/${id}`, workspace);
};
